import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss"
const view = () => {
  return (
    <div
    className="modal fade"
    id="ProfilePictureModal"
    aria-hidden="true"
    aria-labelledby="ProfilePictureModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content  mx-auto  w-auto">
        <div className="modal-header p-0">
          <h1 className="modal-title">Upload a Profile Picture</h1>
        </div>
        
        <div className="modal-body  w-476  p-0">
          <TextfieldLG className=" uni-mt-16  uni-mb-56 ctm-txt">Use a square high resolution picture</TextfieldLG>
          {/* <TextfieldXSM className="ctm-txt2 uni-mb-56">Will require a login</TextfieldXSM> */}
          <div className='profile-pic-white-space'>

          </div>
        </div>  
        <div className="modal-footer uni-mt-56 p-0">
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Cancel</DropworksSmallButton> <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


export const openButton=()=>{
  console.log("ppppppppppppp")
  document.getElementById('okk')?.click();
}