import { FC, useState } from "react";
import "./styles.scss";
import { viewType } from "./modal";
import { UserIcon } from "../../../common/user-icon";
import { TrackIcon } from "../../../common/track-icon";
import { BeatesIcon } from "../../../common/beats-icon";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import { DropworksDropdown } from "../../../common/custom-field/dropworks-dropdown";
import { ProjectIcon } from "../../../common/project-icon";
import Tabs from "../../../common/tabs";
import TabsComponent from "../../../common/tabs-component/view";
import { Heading2, Heading5 } from "../../../common/custom-field/dropstock-heading";

export const view: FC<viewType> = ({ navigateTo }) => {
  const [userarr, setUserarr] = useState([
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
  ]);

  const [dropdownarrRoles, setDropdownarrRoles] = useState({
    heading: "Roles",
    option: [
      "Investor",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  });

  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre0", "Genre1", "Genre2", "Genre3", "Genre4", "Genre5", "Genre6"],
  });
  const [trackarr, setTrackarr] = useState([
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [projectarr, setProjectarr] = useState([
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
  ]);

  const [button, setButton] = useState("all");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value)
  }

  return (
    <div className="search container container-xxl min-height-cover">
      <Heading2 className="search-heading mx-0 my-0">Search Results</Heading2>
      <DropworksSearchInput className="search-inputbox uni-my-40" />
      <Tabs tabsTitle={["All", "Users", "Tracks", "Projects", "Beats"]} className="uni-mb-40" checkTabState={checkTabState} />
      <TabsComponent tabsValue={tabsValue} 
        components={[  
           <>
          <Heading5 className="search-subheading">Users</Heading5>
          <div className="search-usericon">
            <UserIcon arr={userarr.slice(12)} />
          </div>
          <Heading5 className="search-subheading">Tracks</Heading5>
          <div className="search-usericon">
            <TrackIcon arr={trackarr.slice(12)} />
          </div>
          <Heading5 className="search-subheading">Projects</Heading5>
          <div className="search-usericon">
            <ProjectIcon arr={projectarr.slice(12)} />
          </div>
          <Heading5 className="search-subheading">Beats</Heading5>
          <div className="search-usericon">
            <BeatesIcon arr={beatsarr.slice(12)} />
          </div>
        </>,
          <>
          <div className="mb-4">
            <DropworksDropdown arr={dropdownarrRoles} />
          </div>
          <div className="search-usericon">
            <UserIcon arr={userarr} />
          </div>
        </>,
        <>
        <div className="mb-4">
          <DropworksDropdown arr={dropdownarrTracks} />
        </div>
        <div className="search-usericon">
          <TrackIcon arr={trackarr} />
        </div>
      </>,
      <>
      <div className="mb-4">
 
      </div>
      <div className="search-usericon">
        <ProjectIcon arr={projectarr} />
      </div>
    </>,
    <>
        <div className="mb-4">
      
        </div>
        <div className="search-usericon">
          <BeatesIcon arr={beatsarr} />
        </div>
      </>
      ]}/>
    </div>
  );
};
