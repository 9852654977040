import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";

const view = () => {
  return (
    <>
      <div
        className="modal fade"
        id="DeleteAccount"
        aria-hidden="true"
        aria-labelledby="DeleteAccount"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content ">
            <div className="modal-header p-0">
              <h1 className="modal-title">Delete Account</h1>
            </div>
            <div className="modal-body p-0">
              <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">
                Your account will be permanently deleted
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                This action is not reversible. We advise you to withdraw any
                funds or assets from your account before deleting it
                permanently. All deleted assets and funds are not refundable.
              </TextfieldXSM>

              <label htmlFor="" className="text-light uni-mb-16">
                Username
              </label>
              <DropworksInput
                placeholder="Username"
                className="uni-mb-32"
              ></DropworksInput>

              <label htmlFor="" className="text-light uni-mb-16">
                Password
              </label>
              <DropworksInput
                placeholder="Password"
                className="uni-mb-32"
              ></DropworksInput>

              <label htmlFor="" className="text-light uni-mb-16">
                Let us know why you deleted your account
              </label>
              <DropworksTextArea
                className="uni-mb-56 ctm-textarea-delete"
                placeholder="Reason"
              />
            </div>
            <div className="modal-footer p-0">
              <div className="modal-footer p-0">
                {/* <DropworksSmallButton className="btn-wid delete-account-btn my-0 ml-0 uni-mr-16">
                  Cancel
                </DropworksSmallButton>{" "} */}
                <DropworksDisableButton className="btn-wid delete-account-btn my-0 ml-0 uni-mr-16"> Cancel</DropworksDisableButton>
                <DropworksSmallButton className="btn-wid m-0">
                  Confirm
                </DropworksSmallButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default view;
