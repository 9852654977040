import { FC, useState } from "react";
import "./styles.scss";
import { TextfieldLG, TextfieldSM } from "../../../common/custom-field/dropstock-text";
import { Heading4, Heading6 } from "../../../common/custom-field/dropstock-heading";

export const view: FC = () => {
  return (
    <div className="new-wallet container container-xxl min-height-cover">
      <div>
        <div className="overview">
         
          <div className="dropstocks-select-portfolio">
            <div className="table-view">
                
              <div className="table-bg-parent-composite">
              <Heading4 className="dropstocks-select-portfolio-head uni-mb-32">
              DropWorks Composite portfolio  <TextfieldSM className="dropstocks-select-portfolio-subhead">DWC</TextfieldSM> 
          </Heading4>
                <div className="table-wrapper-select-portfolio">
                  <table className="table custom-table-select-portfolio">
                    <thead className="text-dark align-items-center justify-content-between">
                      <tr className="table-row-dropstock">
                        <th scope="col" className="track-column">
                          Track
                        </th>
                        <th scope="col">Added</th>
                        <th scope="col" className="composit-quantity">Quantity</th>
                        <th scope="col" className="anual-return-composit">Est. Annual return</th>
                      </tr>
                    </thead>
                    <tbody>{[1,2,3,4,,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,21,22,23,24,25,26,27,28,29,30,21,22,23,24,25,26,27,28,29,30,21,22,23,24,25,26,27,28,29,30].map((ele)=>{
                        return( <tr className="text-light d-flex justify-content-between align-items-center">
                            <td className="date-time track-column">
                              <div className="d-flex gap-3 align-items-center">
                                <span className="select-box"></span>
                                <div className="d-flex">
                                  <Heading6 className="my-0 p-0">
                                    Track Name 1
                                  </Heading6>
                                </div>
                              </div>
                            </td>
                            <td >08/23/24</td>
                            <td className="composit-date">800<span className="sub-quantity-price">/5000</span></td>
                            <td className="composit-table-last-data">4.1%</td>
                          </tr>)
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};
