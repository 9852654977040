import './styles.scss'
import { viewType } from "./modal";
import { FC } from "react";
const view:FC<viewType> = ({ navigateTo }) => {
  return (
    <div className='create container'>
        <h1 className='create-heading uni-mb-32'>Create</h1>
        <div className='create-content'>
            <div className='create-content-section1 uni-mb-40' onClick={navigateTo("/beat-empty")}><p className='uni-ml-56 uni-mb-24'>Add a <span>BEAT</span> to my library </p></div>
            <div className='create-content-section2 uni-mb-40'><p className='text-end uni-mr-56  uni-mb-24'>Create a new <span>TRACK</span></p></div>
            <div className='create-content-section3 uni-mb-40' onClick={navigateTo("/track-project-overview")}><p className='uni-ml-56  uni-mb-24'>Add an already <span>RELEASED TRACK</span> to DropWorks</p></div>
        </div>
    </div>
  )
}

export default view