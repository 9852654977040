import { FC, useState } from "react";
import "./styles.scss";
import trackImage from "../../../assets/img/temporary-images/track-image.svg";
import userImage from "../../../assets/img/Profile-pics/kingroland-image.png";
import { UserLable } from "../../../common/user-labels";
import { MeatballsMenuDropdown } from "../../../common/icons";
import {
  BlueTik,
  HeartIcon,
  TrackPlayBackBtn,
  YelloTik,
} from "../../../common/all-icons";
import { AudioPlayer } from "../../shared/components/audio-player";

export const view: FC = () => {
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Block",
    "Report",
  ]);
  const [songTeam, setSongTeam] = useState([
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Producer",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Maba",
      title: "Vocalist",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Kahino",
      title: "Beat Maker",
      goldTik: true,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "Lala Baby",
      title: "Lyricist ",
      goldTik: false,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Artwork Designer",
      goldTik: false,
      blueTik: true,
    },
    {
      img: `${userImage}`,
      fullName: "King Roland",
      title: "Curator",
      goldTik: false,
      blueTik: true,
    },
  ]);
  console.log(songTeam);

  return (<>
  
    <div className="track-page container container-xxl min-height-cover">
      <div className="track-page-body uni-mb-56">
        <div>
          <img src={trackImage} className="track-page-image" alt="user-image" />
        </div>
        <div className="track-page-content">
          <div className="w-100">
            <div className="track-page-details">
              <h1 className="track-page-name">Hot Summer</h1>
              <div className="track-page-playback-Btn">
                <TrackPlayBackBtn />
              </div>
            </div>
            <div className="track-page-song-title">
              King Roland, Mabapupi Ocachino,Kahino, Lala Baby
            </div>
          </div>
          <div className="d-flex gap-3 mt-2 w-100">
            <UserLable>Track</UserLable>
            <UserLable className={`user-lables-gradient`}>
              DropWorks Composite
            </UserLable>
            <UserLable className={`user-lables-gradient2`}>
              DropWorks Select
            </UserLable>
            <HeartIcon />
        <MeatballsMenuDropdown
          arr={MeatballsMenuOptions}
          height={"6px"}
        ></MeatballsMenuDropdown>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap">
        {songTeam.map((item: any, index: number) => (
          <div key={index} className="col-5 uni-mb-16">
            <div>
              <div>
                <h4 className="track-page-subheading d-flex">
                  {item.title}
                  {item.goldTik ? (
                    <div className="uni-ml-8">
                      <YelloTik />
                    </div>
                  ) : (
                    ""
                  )}
                </h4>
              </div>
              <div className="track-page-sub-content mb-4">
                <img
                  src={item.img}
                  className="track-page-sub-content-img"
                  alt=""
                />
                <div className="track-page-sub-details">
                  <h5 className="track-page-sub-song-name">{item.fullName}</h5>

                  {item.blueTik ? (
                    <div className="uni-ml-8">
                      <BlueTik />
                    </div>
                  ) : (
                    ""
                  )}

                  <MeatballsMenuDropdown
                    arr={MeatballsMenuOptions}
                    height={"5px"}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
  );
};
