import { useNavigate } from "react-router-dom"
import view from "./view";

export const MarketPlaceDropworkComposite=()=>{

    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({navigateTo}))
}