import { FC, useState } from "react";
import "./styles.scss";
import { Link, Outlet } from "react-router-dom";
import iconimage from "../../../../assets/img/dropworklogo.png";
import { IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoMenu } from "react-icons/io5";
import { Footer } from "../footer";
import BellIcon from "../../../../assets/steps/bell.svg";
import NotificationIcon from "../../../../assets/steps/envelope.svg";
import UserIcon from "../../../../assets/steps/Group 18414.svg";
import beatsImage from "../../../../assets/img/temporary-images/beat-image.svg";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";
import projectImage from "../../../../assets/img/temporary-images/project-image.svg";
import BeatArtWork from "../pop-ups/beat-artwork";
import SearchInvite from "../pop-ups/search-invite";
import InviteModal8 from "../pop-ups/invite-modal-8";
import InviteModal2 from "../pop-ups/invite-modal-2";
import UploadModal from "../pop-ups/upload-modal";
import ReleasedModal from "../pop-ups/release-modal";
import ActiveInviteReceived from "../pop-ups/active-invite-received";
import DeclinedInviteReceived from "../pop-ups/declined-invite-received";
import AcceptedInviteReceived from "../pop-ups/accepted-invite-received";
import ExpiredInviteReceived from "../pop-ups/expired-invite-received";
import AcceptedInviteSent from "../pop-ups/accepted-invite-sent";
import ActiveInviteSent from "../pop-ups/active-invite-sent";
import DeclinedInviteSent from "../pop-ups/declined-invite-sent";
import ExpiredInviteSent from "../pop-ups/expired-invite-sent";
import InviteModal from "../pop-ups/invite-modal";
import BlockedModal from "../pop-ups/blocked-modal";
import SuccessfullyBlocked from "../pop-ups/successfully-blocked";
import ReportModal1 from "../pop-ups/report-modal-1";
import ReportModal2 from "../pop-ups/report-modal-2";
import ReportModal3 from "../pop-ups/report-modal-3";
import ReportModal4 from "../pop-ups/report-modal-4";
import ReportRecentChatModal from "../pop-ups/report-recent-chat";
import ThankYouModal from "../pop-ups/thank-you-modal";
import TrackReportModal from "../pop-ups/track-report-reason";
import UserReportReasonModal from "../pop-ups/user-report-reason";
import BeatReportModal from "../pop-ups/beat-report-reason";
import ImpersonatingReportModal from "../pop-ups/impersonating-report-modal";
import SelectTrack from "../pop-ups/select-track";
import SelectBeat from "../pop-ups/select-beat";
import { AudioPlayer } from "../audio-player";
import RolesModal from "../pop-ups/roles-modal";
import DawsModal from "../pop-ups/daws-modal";
import Modal2 from "../pop-ups/modal-2";
import EmailModal from "../pop-ups/email-modal";
import BlockedUser1 from "../pop-ups/blockuser-1";
import BlockedUser2 from "../pop-ups/blockuser-2";
import Modal1 from "../pop-ups/modal-1";
import DepositModal from "../pop-ups/deposit-modal";
import WithdrawModal from "../pop-ups/withdraw-modal";
import ChangeAlias from "../pop-ups/change-alias";
import RemoveCollaborator from "../pop-ups/remove-collaborator";
import DeleteChat from "../pop-ups/delete-chat";
import DropWorksSelectSubmit from "../pop-ups/dropworks-select-submit";
import SubMissionThankyou from "../pop-ups/submission-thankyou";
import SellDropStocks from "../pop-ups/sell-dropstocks";
import SellDropStocks3 from "../pop-ups/sell-dropstocks-3";
import DropStocksSelectsubmit3 from "../pop-ups/dropworks-select-submit-3";
import DropWorksCompositSubmit from "../pop-ups/dropworks-composit-submit";
import ApproveFile2 from "../pop-ups/approve-file-2";
import DeleteFile from "../pop-ups/delete-file";
import DeclineFile from "../pop-ups/decline-file";
import ApproveFile from "../pop-ups/approve-file";
import SocialLinksMain from "../pop-ups/socail-links-main";
import UploadBanner2 from "../pop-ups/upload-banner-2";
import UploadBanner from "../pop-ups/upload-banner";
import LoginHistorySocials from "../pop-ups/login-history";
import SocialLinksNotification from "../pop-ups/social-links-notification";
import AccountSecured from "../pop-ups/acc-secured";
import SecureYourAccount from "../pop-ups/secure-your-account";
import SocialLink8 from "../pop-ups/social-links-8";
import SocialLink14 from "../pop-ups/social-links-14";
import SocialLink9 from "../pop-ups/social-links-9";
import SocialLink13 from "../pop-ups/social-links-13";
import SocialLink10 from "../pop-ups/social-links-10";
import SocialLink12 from "../pop-ups/social-links-12";
import SocialLink11 from "../pop-ups/social-links-11";
import EmailModal1 from "../pop-ups/email-modal-1";
import EmailModal2 from "../pop-ups/email-modal-2";
import EmailModal6 from "../pop-ups/email-modal-6";
import MobilePhoneModal3 from "../pop-ups/mobile-phone-modal-3";
import MobilePhoneModalA from "../pop-ups/mobile-phone-modal-A";
import MobilePhoneModalB from "../pop-ups/mobile-phone-modal-B";
import ProfilePictureModal from "../pop-ups/profile-picture-modal";
import ProfilePictureModal2 from "../pop-ups/profile-picture-modal-2";
import DisplayNameModal from "../pop-ups/display-name";
import BiographyModal from "../pop-ups/biography-modal";
import SecurityQuestion from "../pop-ups/security-question";
import DisplayLanguage from "../pop-ups/display-language";
import RegionModal from "../pop-ups/region-modal";
import DeleteAccount from "../pop-ups/delete-account";
import DeleteAccount3Modal from "../pop-ups/delete-account3";
import ChangePasswordModal3 from "../pop-ups/change-password3";
import ChangePasswordModal2 from "../pop-ups/change-password2";
import ChangePasswordModal from "../pop-ups/change-password";
import SecurityQuestion2 from "../pop-ups/security-question 2";

export const view: FC<any> = ({ navigateTo }) => {
  const [locationValue, setLocationValue] = useState(location.pathname || "");

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  function showSidebar() {
    const sidebar = document.querySelector(
      ".newstudioheader_content_nav_sidebar"
    );
    if (sidebar) {
      sidebar.setAttribute("style", "display: flex;");
    }
  }

  function hideSidebar() {
    const sidebar = document.querySelector(
      ".newstudioheader_content_nav_sidebar"
    );
    if (sidebar) {
      sidebar.setAttribute("style", "display: none;");
    }
  }

  const openSubmenu = () => {
    const button = document.getElementById("neww");
    button &&
      button.addEventListener("click", function () {
        this.classList.toggle("active");
      });
  };

  return (
    <div className="newstudioheader">
      <ApproveFile2 />
      <DeleteFile />
      <DeclineFile />
      <ApproveFile />
      <SocialLinksMain />
      <UploadBanner2 />
      <UploadBanner />
      <LoginHistorySocials />
      <SocialLinksNotification />
      <AccountSecured />
      <SecureYourAccount />
      <SocialLink8 />
      <SocialLink14 />
      <SocialLink9 />
      <SocialLink13 />
      <SocialLink10 />
      <SocialLink12 />
      <SocialLink11 />
      <EmailModal1 />
      <EmailModal2 />
      <EmailModal6 />
      <MobilePhoneModal3 />
      <MobilePhoneModalA />
      <MobilePhoneModalB />
      <ProfilePictureModal />
      <ProfilePictureModal2 />
      <DisplayNameModal />
      <BiographyModal />
      <SecurityQuestion />
      <SecurityQuestion2 />
      <ChangePasswordModal />
      <ChangePasswordModal2 />
      <ChangePasswordModal3 />
      <DeleteAccount />
      <DeleteAccount3Modal />
      <RegionModal />
      <DisplayLanguage />
      <ActiveInviteReceived />
      <AcceptedInviteReceived />
      <DeclinedInviteReceived />
      <ExpiredInviteReceived />
      <AcceptedInviteSent />
      <ActiveInviteSent />
      <DeclinedInviteSent />
      <ExpiredInviteSent />
      <ReportModal1 />
      <ReportModal2 />
      <ReportModal3 />
      <ReportModal4 />
      <ReportRecentChatModal />
      <SuccessfullyBlocked />
      <ThankYouModal />
      <TrackReportModal />
      <UserReportReasonModal />
      <BeatReportModal />
      <ImpersonatingReportModal />
      <RolesModal />
      <DawsModal />
      <InviteModal />
      <InviteModal2 />
      <Modal2 />
      <EmailModal />
      <BlockedUser1 />
      <BlockedUser2 />
      <BlockedModal />
      <Modal1 />
      <SelectTrack />
      <SelectBeat />
      <BeatArtWork />
      <ReleasedModal />
      <UploadModal />
      <DepositModal />
      <WithdrawModal />
      <InviteModal8 />
      <SearchInvite />
      <ChangeAlias />
      <RemoveCollaborator />
      <DeleteChat />
      <DropWorksSelectSubmit />
      <SubMissionThankyou />
      <SellDropStocks />
      <SellDropStocks3 />
      <DropStocksSelectsubmit3 />
      <DropWorksCompositSubmit />
      <ReleasedModal />
      {locationValue === "/user-profile" ||
        locationValue === "/beats-page" ||
        locationValue === "/track-page" ||
        locationValue === "/project-page" ? (
        <div
          className="newstudioheader-dynamic-image"
          style={{
            backgroundImage:
              locationValue === "/beats-page"
                ? `url(${beatsImage})`
                : locationValue === "/track-page"
                  ? `url(${trackImage})`
                  : locationValue === "/project-page"
                    ? `url(${projectImage})`
                    : `url(https://picsum.photos/200/300)`,
          }}
        ></div>
      ) : null}
      <div className="newstudioheader_content">
        <div className="newstudioheader_content_nav">
          <ul className="newstudioheader_content_nav_sidebar"> 
            <div className="newstudioheader_content_nav_sidebar_logo">
              <li>
                <Link to="/home">
                  <img src={iconimage} />
                </Link>
              </li>
              <li onClick={hideSidebar}>
                <Link to="#">X</Link>
              </li>
            </div>
            <li>
              <Link to="/marketplace-products">Products</Link>
            </li>
            <li>
              <Link to="#">Trade</Link>
            </li>
            <li>
              <Link to="#">Portfolio</Link>
            </li>
            <li className=" newstudioheader_content_nav_sidebar_links">
              <Link to="#" onClick={toggleDropdown}>
                Studio <MdKeyboardArrowDown />
              </Link>
              {isDropdownOpen ? (
                <ul>
                  <li>
                    <a href="#">Featured</a>
                  </li>
                  <li>
                    <a href="#">DropStocks</a>
                  </li>
                  <li>
                    <a href="#">DropOffers</a>
                  </li>
                  <li>
                    <a href="#">Collection</a>
                  </li>
                </ul>
              ) : (
                ""
              )}
            </li>
          </ul>
          <div className="newstudioheader_content_nav_navbar">
            <div className="newstudioheader_content_nav_navbar_logo ">
              <li>
                <Link to="/home">
                  <img src={iconimage} />
                </Link>
              </li>
            </div>
            <ul className="newstudioheader_content_nav_navbar_first ">
              <li className="hideOnMobile newstudioheader_content_nav_navbar_first_links_products dropdown">
                <Link to="/marketplace-products">
                  Products <MdKeyboardArrowDown className="uni-ml-8" />
                  <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu ">
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                      <Link to="/marketplace-dropworks-composite">Dropworks Composite</Link>
                    </li>
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                      <Link to="/marketplace-dropworks-select">Dropworks Select</Link>
                    </li>
                  </ul>
                </Link>
              </li>
              <li className="hideOnMobile  newstudioheader_content_nav_navbar_first_links_trade dropdown">
                <Link to="#">
                  Trade <MdKeyboardArrowDown className="uni-ml-8" />
                  <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu ">
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                      <Link to="/dropworks-select-composite">DropWorks Composite</Link>
                    </li>
                    <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                      <Link to="/dropworks-select">Dropworks Select</Link>
                    </li>
                  </ul>
                </Link>
              </li>
              <li className="hideOnMobile">
                <Link to="/personal-portfolio">Portfolio </Link>
              </li>
              <li className="hideOnMobile newstudioheader_content_nav_navbar_first_links dropdown">
                <Link to="/featured">
                  Studio <MdKeyboardArrowDown className="uni-ml-8" />
                </Link>
                <ul className="newstudioheader_content_nav_navbar_first_links_dropdown dropdown_menu ">
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                    <Link to="/featured">Featured</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                    <Link to="/library">Library</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                  <Link to="/invite">Invites</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_first_links_dropdown_submenu  hideOnMobile  ">
                    <Link to="/create">Create</Link>
                  </li>
                </ul>
              </li>
              <li className="hideOnMobile" onClick={navigateTo("/search")}>
                <IoSearchSharp className="cursor-pointer" />
              </li>
            </ul>

            <ul className="newstudioheader_content_nav_navbar_second">
              <li className="mar-mr-12">
                <Link to="/notification2">
                  <img src={BellIcon} />
                </Link>
              </li>
              <li className="me-0">
                <Link to="/message-chat">
                  <img src={NotificationIcon} />
                </Link>
              </li>
              <li className="mar-mx-24  newstudioheader_content_nav_navbar_second_links">
                <Link to="#">#USERNAME</Link>
                <ul className="newstudioheader_content_nav_navbar_second_links_dropdown dropdown ">
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  ">
                    <Link to="/new-wallet">Wallet</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  ">
                    <Link to="/user-profile">Profile</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  ">
                    <Link to="/profile-setting">Setting</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  ">
                    <Link to="/favorites">Favorites</Link>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  ">
                    <a href="#">Demo Account</a>
                  </li>
                  <li className="newstudioheader_content_nav_navbar_second_links_dropdown_submenu hideOnMobile  ">
                    <a href="#">Logout</a>
                  </li>
                </ul>
              </li>
              <li className="imgg  newstudioheader_content_nav_navbar_second_links">
                <Link to="#">
                  <img src={UserIcon} />
                </Link>
              </li>
              <li className="menu-button" onClick={showSidebar}>
                <Link to="#">
                  <IoMenu />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="newstudioheader_outlet">
        {/* <CustomScrollbarReact> */}
        <Outlet />
        <Footer />
        {/* </CustomScrollbarReact> */}
      </div>
      {locationValue === "/track-page" ? (
        <>
          <div
            className="d-flex  w-100 "
            style={{ background: "rgb(0 0 0)", height: "97px" }}
          ></div>
          <AudioPlayer />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
