import { useNavigate } from "react-router-dom"
import { view } from "./view"
interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {
  }
export const DropworksDropdownCountry:React.FC<CustomDivProps> =({...props})=>{

    
    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }

    return(view({...props}))
}