import view from "./view";
import { Application } from '@splinetool/runtime';
import { splineArr } from './splineData'
import { useRef, useEffect } from "react";
const LogoHeader=()=>{
    
    const dropAnimationRef:any = useRef(null);

    // useEffect(() => {
    //     const canvas:any = dropAnimationRef.current;
    //     const spline:any = new Application(canvas);
    //     spline.start(splineArr)
    // },[]);

    return(view({dropAnimationRef}))
}

export default LogoHeader;