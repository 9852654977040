import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="UploadBanner"
      aria-hidden="true"
      aria-labelledby="UploadBanner"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
       
          <div className="modal-content modal-width-upload-banner ">
            <div className="modal-header p-0">
              <h1 className="modal-title">Upload a Profile Picture</h1>
            </div>

            <div className="modal-body p-0 uni-mb-56">
              <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-32">Use a square high resolution picture</TextfieldLG>

              <ButtonComponent id="ProfilePictureModal" >
              <div className="uploadBanner_uploadbox mx-0 my-0">
                <img src={iconImg} className='uploadBanner-uploadicon' />
                <TextfieldSM className='text-content uploadBanner-upload-text mx-0 my-0'>Upload</TextfieldSM>
              </div>
              </ButtonComponent>
            </div>
            <div className="modal-footer p-0">
              <DropworksSmallButton className="btn-wid m-0">Save</DropworksSmallButton>
            </div>
          </div>
        
      </div>
    </div>
  )
}

export default view;

