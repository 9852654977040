import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksLargeButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import userIcon from '../../../../../assets/steps/UserIcon.svg';
import blockedUserIcon from "../../../../../assets/steps/blockUserIcon.svg";
const view = () => {
  return (
    <div
    className="modal fade"
    id="BlockedUser2"
    aria-hidden="true"
    aria-labelledby="BlockedUser2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Blocked Users</h1>
        </div>
        
        <div className="modal-body uni-mb-56 ">
          <TextfieldLG className=" uni-mt-16 uni-mb-56 ctm-txt">Manage blocked users</TextfieldLG>
          <div className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                 <img src={userIcon} alt="" />
                 <TextfieldSM className='block-username'>#USERNAME</TextfieldSM>
              </div>
               <div>
               <DropworksDisableButton className='unblock-button'>Unblock</DropworksDisableButton>
               </div>
          </div>
          <div className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                 <img src={userIcon} alt="" />
                 <TextfieldSM className='block-username'>#USERNAME</TextfieldSM>
              </div>
               <div>
                 <DropworksDisableButton className='unblock-button'>Unblock</DropworksDisableButton>
               </div>
          </div>
          <div className='d-flex uni-mb-24 blocked-user-parent align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-3'>
                 <img src={blockedUserIcon} alt="" />
                 <TextfieldSM className='block-undo'>#USERNAME</TextfieldSM>
              </div>
               <div>
                 <DropworksDisableButton className='undo-button'>Undo</DropworksDisableButton>
               </div>
          </div>
        </div>  
        <div className="modal-footer p-0">
        <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

