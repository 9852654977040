import React, { FC } from "react";
import "./styles.scss";
import { IoCloseCircleOutline } from "react-icons/io5";
import clipIcon from "../../../assets/steps/clip.svg";
import sendIcon from "../../../assets/steps/send button.svg";

import searchCrossIcon from "../../../assets/img/searchCrossIcon.svg";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearInput: () => void;
  sendMessage: () => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void; // Updated type
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: any;
  FunctionOnSecondSVG?:()=>void
}

export const view: FC<InputProps> = ({
  inputValue,
  handleInputChange,
  clearInput,
  sendMessage,
  handleKeyDown,
  className = "",
  placeholder = "Search",
  ...rest
}) => {
  return (
    <div className={`message-inp  ${className}`}>
      <img src={clipIcon} className="message-inp-icon " alt="icon" />

      <input
        className="message-inp-input"
        {...rest}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Added onKeyDown event handler
        placeholder={placeholder}
      />
      <span className="message-inp-closeicon">
        {/* {inputValue.length > 0 && ( */}
          <img
            src={sendIcon}
            className="message-inp-cross-icon"
            onClick={sendMessage}
          />
        {/* )} */}
      </span>
    </div>
  );
};
