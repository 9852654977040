import { FC, useState } from "react";
import "./styles.scss";
import { DropworksDropdown } from "../../../../common/custom-field/dropworks-dropdown";
import { TrackIcon } from "../../../../common/track-icon";
import { ProjectIcon } from "../../../../common/project-icon";
import { DropworkGhostButton } from "../../../../common/custom-field/dropworks-button";
import { BeatesIcon } from "../../../../common/beats-icon";

export const view: FC = () => {
  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre", "Genre", "Genre", "Genre", "Genre", "Genre", "Genre"],
  });
  const [trackarr, setTrackarr] = useState([
    { heading: "Track Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Track Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Track Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Track Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Track Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Track Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
  ]);

  const [projectarr, setProjectarr] = useState([
    { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
    { heading: "Project Name", subheading: "Unlost, Uncovered, Meyna, Maluma and more" },
  ]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
  ]);

  const [button, setButton] = useState("all");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };

  return (
    <div className="library container container-xxl min-height-cover">
      <div className="library-heading">Library</div>
      <div className="library-button d-flex flex-wrap mb-4">
        <button
          className={`library-button-all ${button === "all" ? "active" : ""}`}
          onClick={() => handleButtonClick("all")}
        >
          All
        </button>
        <button
          className={`library-button-all ${
            button === "tracks" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("tracks")}
        >
          Tracks
        </button>
        <button
          className={`library-button-all ${
            button === "projects" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("projects")}
        >
          Projects
        </button>
        <button
          className={`library-button-all ${button === "beats" ? "active" : ""}`}
          onClick={() => handleButtonClick("beats")}
        >
          Beats
        </button>
      </div>

      {button === "all" || button === "tracks" ? (
        <>
          {button === "tracks" ? (
            ""
          ) : (
            <div className="library-subheading">Your Tracks</div>
          )}
          <div className="library-usericon">
            <TrackIcon arr={trackarr} />
          </div>
        </>
      ) : null}

      {button === "all" || button === "projects" ? (
        <>
          {button === "projects" ? (
            <>
            <div className="d-flex gap-4 mb-4">
            <DropworkGhostButton >Active</DropworkGhostButton>
            <DropworkGhostButton>Released</DropworkGhostButton>
            </div>
            </>
          ) : (
            <div className="library-subheading">Your Projects</div>
          )}

          <div className="library-usericon">
            <ProjectIcon arr={projectarr} />
          </div>
        </>
      ) : null}

      {button === "all" || button === "beats" ? (
        <>
          {button === "beats" ? (
            ""
          ) : (
            <div className="library-subheading">Your Beats</div>
          )}

          <div className="library-usericon">
            <BeatesIcon arr={beatsarr} />
          </div>
        </>
      ) : null}
    </div>
  );
};
