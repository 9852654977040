import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksMediumButton,
  DropworksNormalButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import {
  Heading0,
  Heading3,
  Heading4,
} from "../../../common/custom-field/dropstock-heading";
import TabsComponent from "../../../common/tabs-component/view";
import Tabs from "../../../common/tabs";
import InfiniteImageScroll from "../../../common/infinite-image-scroll";
import { ButtonComponent } from "../../shared/components/logo-header/view";
const view: FC<viewType> = ({ navigateTo }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2, // Adjust to 2, since you are using 2 rows and want to fit the grid
    speed: 500,
    rows: 3, // 2 rows of slides
    slidesPerRow: 1, // Display 2 slides per row
    autoplay: false, // Enable autoplay
    autoplaySpeed: 1000, // Time between slides in ms
    arrows: false,
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  return (
    <div className=" marketplace-dw-composite-main">
      <div className="row marketplace-dw-composite-main-firstrow">
        {/* Left Section */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 marketplace-dw-composite-main-firstrow-col1 px-0 uni-px-24">
          <div className="marketplace-dw-composite-main-firstrow-col1-content">
            <Heading0 className="text-wrap uni-mb-40">
              Dropworks
              <br />
              <span className="gradient-text">Select</span>
            </Heading0>
            <TextfieldMD className="uni-mb-40 marketplace-dw-composite-main-firstrow-col1-content-sm px-0 mx-0">
              We bring you established hits and emerging favorites from artists
              who are making waves. Whether you’re a music enthusiast looking to
              explore new sounds or an artist wanting to reach a broader
              audience, Dropworks Select offers something unique.
            </TextfieldMD>
            <div className="d-flex">

                <ButtonComponent id="SelectSubmit"><DropworksMediumButton className="w-auto uni-px-72">
                Submit your proposal
              </DropworksMediumButton></ButtonComponent>
              <DropworksMediumButton className="marketplaceProduct-main-thirdrow-content-title-tradebtn  uni-px-72 uni-ml-40 w-auto"  onClick={navigateTo("/dropworks-select")}>
                Trade now!
              </DropworksMediumButton>

          
       
            </div>
          </div>
        </div>

        <div className=" col-12 col-sm-12 col-md-12 col-lg-6 uni-pl-40 pe-0">
          <InfiniteImageScroll />
        </div>
      </div>

      <div className="marketplace-dw-composite-main-thirdrow mx-auto justify-content-center px-0">
        <div className="marketplace-dw-composite-main-thirdrow-left mx-auto px-0">
          <Heading4 className="text-center uni-mb-16">
            Ownership Opportunity
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56">
            If you hold Dropstocks of a re-released track, you have the unique
            opportunity to propose your track for inclusion in Dropworks Select.
            This allows your music to reach a wider audience and potentially
            increase in value.
          </TextfieldMD>
          <Heading4 className="text-center uni-mb-16">
            Submit an Application
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56">
            Owners can submit a proposal to sell their Dropstocks to Dropworks.
            You’ll provide details about the track, its performance, and why it
            deserves a place in Dropworks Select.
          </TextfieldMD>
          <Heading4 className="text-center uni-mb-16">
            Evaluation Process
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56">
            Our team will carefully review your submission, evaluating the
            track's quality, market potential, and alignment with Dropworks
            Select’s standards.
          </TextfieldMD>
          <Heading4 className="text-center uni-mb-16">
            Acceptance & Integration
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56">
            If your proposal is accepted, we’ll purchase the Dropstocks from
            you, and your track will be featured in Dropworks Select. It will
            then be available for others to own, trade, and enjoy, all while you
            benefit from its success.
          </TextfieldMD>
        </div>
        <div className="marketplace-dw-composite-main-thirdrow-right">
          <div className="marketplace-dw-composite-main-thirdrow-right-content">
            <Heading0 className="uni-mb-40">
              Ready to Elevate Your Track?
            </Heading0>
            <DropworksNormalButton className="w-auto uni-px-72">
              Submit your proposal
            </DropworksNormalButton>
          </div>
        </div>

      </div>
    </div>
  );
};

export default view;
