import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import iconImg from "../../../../../assets/img/upload-icon.png";

const view = () => {
  return (
    <div
      className="modal fade"
      id="UploadModal"
      aria-hidden="true"
      aria-labelledby="UploadModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered    mx-auto   custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header uni-mb-32 p-0">
            <h1 className="modal-title">Upload a File</h1>
          </div>

          <div className="modal-body p-0">
            <TextfieldLG className="text-content mt-0 uni-mb-16">
              File Type
            </TextfieldLG>
            <DropworksSelect className="uni-mb-32">
              <option value="">Select</option>
            </DropworksSelect>

            <TextfieldLG className="text-content uni-mb-16 mt-0">
              Short Description
            </TextfieldLG>
            <DropworksInput placeholder="Type" className="uni-mb-32" />

            <TextfieldLG className="text-content uni-mb-16 mt-0">
              File
            </TextfieldLG>
            <div className="inviteModal_uploadbox mx-0 my-0">
              <img src={iconImg} className="uploadicon" />
              <TextfieldSM className="text-content upload-text mx-0 my-0">
                Upload
              </TextfieldSM>
            </div>
          </div>
          <div className="modal-footer p-0 uni-mt-32">
            <DropworksDisableButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksDisableButton>
            <DropworksDisableButton className="btn-wid m-0 custom-btn-upload">
              Upload
            </DropworksDisableButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
