import { FC, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

export const DropworksPassword:FC<InputProps>=({...props})=>{
    const [show,setShow]=useState<boolean>(true);
    const showHide=()=>{
        setShow(!show)
    }
    return view({showHide,show,...props});
}