import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="Modal4"
    aria-hidden="true"
    aria-labelledby="Modal4"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
        <div className="modal-header p-0">
          <h1 className="modal-title">Secure your Account</h1>
        </div>
        
        <div className="modal-body p-0">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Please confirm the code we’ve sent to your phone</TextfieldLG>
          <DropworksInput className="mb-0" placeholder="Code" />
          <TextfieldXSM className="ctm-txt2 uni-mb-56">Resend Code</TextfieldXSM>
        </div>  
        <div className="modal-footer p-0">
        <ButtonComponent id="acc-secured">
          <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
          </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


export const openButton=()=>{
  console.log("ppppppppppppp")
  document.getElementById('okk')?.click();
}