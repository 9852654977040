import { FC, useState } from "react";
import "./styles.scss";
import kingRoland from "../../../assets/img/roland.png";
import { InviteFriends } from "../../../common/invite-friends";

export const view: FC = () => {
  const [recivedearr, setRecivedarr] = useState([
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
      id: "ActiveInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
      id: "ActiveInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
      id: "ActiveInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
      id: "ActiveInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
      id: "ActiveInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
      id: "ActiveInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Declined",
      id: "DeclinedInviteReceived"

    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Accepted",
      id: "AcceptedInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Accepted",
      id: "AcceptedInviteReceived"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Expired",
      id: "ExpiredInviteReceived"
    },
  ]);

  const [sentarr, setSentarr] = useState([
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
       id: "ActiveInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
       id: "ActiveInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
       id: "ActiveInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
       id: "ActiveInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
       id: "ActiveInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Active",
       id: "ActiveInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Declined",
       id: "DeclinedInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Accepted",
       id: "AcceptedInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Accepted",
       id: "AcceptedInviteSent"
    },
    {
      img: kingRoland,
      name: "King Roland",
      subname: "@kingroland",
      vocalist: "Vocalist",
      percentage: "15%",
      active: "Expired",
       id: "ExpiredInviteSent"
    },
  ]);





  const [selectedArr, setSelectedArr] = useState<any>(recivedearr);
  const [currentSection, setCurrentSection] = useState("received");
  const [status, setStatus] = useState("");

  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
    setSelectedArr(section === "received" ? recivedearr : sentarr);
    setStatus("");
  };

  const handleStatusClick = (statusType: string) => {
    setStatus(statusType);
    let filteredArr: any;

    if (statusType === "Active") {
      filteredArr = (currentSection === "received" ? recivedearr : sentarr).filter(
        item => item.active === "Active"
      );
    } else if (statusType === "Expired") {
      filteredArr = (currentSection === "received" ? recivedearr : sentarr).filter(
        item => ["Expired", "Accepted", "Declined"].includes(item.active)
      );
    }

    setSelectedArr(filteredArr);
  };




  return (
    <div className="invite container-1408 container min-height-cover">
      <h1 className="invite-heading uni-mb-32">Invites</h1>
      <div className="invite-button uni-mb-32 d-flex flex-wrap">
        <button
          className={`invite-button-all ${currentSection === "received" ? "active" : ""}`}
          onClick={() => handleSectionClick("received")}
        >
          Received
        </button>
        <button
          className={`invite-button-all ${currentSection === "sent" ? "active" : ""}`}
          onClick={() => handleSectionClick("sent")}
        >
          Sent
        </button>
      </div>
      <div className="d-flex invite-acexp flex-wrap uni-mb-32">
        <button
          className={`invite-acexp-btn ${status === "Active" ? "active" : ""}`}
          onClick={() => handleStatusClick("Active")}
        >
          Active
        </button>
        <button
          className={`invite-acexp-btn ${status === "Expired" ? "active" : ""}`}
          onClick={() => handleStatusClick("Expired")}
        >
          Expired
        </button>
      </div>
      <div className="invite-usericon">
        <InviteFriends arr={selectedArr} />
      </div>
    </div>
  );
};
