import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { BlueTik } from '../../../../../common/all-icons';
import kingImage from "../../../../../assets/img/king.png"

const view = () => {
  return (
    <div
      className="modal fade"
      id="DeleteFile"
      aria-hidden="true"
      aria-labelledby="DeleteFile"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content approve-modal-width m-auto">
          <div className="modal-header p-0 uni-mb-32">
            <h1 className='approve-modal-title mx-0 my-0'>Delete file?</h1>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            <TextfieldLG className='approve-modal-subtitle uni-mb-32 mt-0'>Doing so will permanently remove this file from the project</TextfieldLG>
            <TextfieldSM className='text-g3 mx-0 my-0'>Type:<span className='text-g5'>Final track</span></TextfieldSM>
            <TextfieldSM className='text-g3 mx-0 my-0'>Description:  <span className='text-g5'>Finalized track for release</span></TextfieldSM>
            <TextfieldSM className='text-g3 mx-0 my-0'>Date:<span className='text-g5'> mm/dd/yyyy hh:mm</span></TextfieldSM>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3-approve my-0 ml-0">Cancel</DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0">Delete</DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


