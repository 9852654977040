import React, { FC } from "react";
import "./styles.scss";
import { IoCloseCircleOutline } from "react-icons/io5";

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> { 
    arr: { heading: string; option: string[] };
    selectedRoles: string[];
    handleRoleChange: (role: string) => void;
}

export const view: FC<DropdownProps> = ({ arr, selectedRoles, handleRoleChange }) => {
    return (
        <div className="dropworks-dropdown">
            <button
                className="dropworks-dropdown-button dropdown-toggle"
                type="button"
                id="dropdownMenuClickableInside"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                {arr.heading}
            </button>

            <ul className="dropdown-menu dropworks-dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                {arr.option.map((data) => (
                    <li key={data}>
                        <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option">
                            <span className="text-wrap">{data}</span>
                            <input
                                type="checkbox"
                                name="role"
                                value={data}
                                checked={selectedRoles.includes(data)}
                                onChange={() => handleRoleChange(data)}
                                className="custom-checkbox me-2"
                            />
                            <span className="custom-radio"></span>
                        </label>
                    </li>
                ))}
            </ul>

            {selectedRoles.map((role) => (
                <button key={role} className="dropworks-dropdown-button">
                    {role}{" "}
                    <IoCloseCircleOutline onClick={() => handleRoleChange(role)} className="dropworks-dropdown-button-icon" />
                </button>
            ))}
        </div>
    );
};
