import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksNormalButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import {
  Heading0,
  Heading3,
  Heading4,
} from "../../../common/custom-field/dropstock-heading";
import Slider from "react-slick";
import image from "../../../assets/img/roland.png";
import TabsComponent from "../../../common/tabs-component/view";
import Tabs from "../../../common/tabs";
import scarollImage from "../../../assets/img/Maintenance.jpg";
import ProjectPageImg from "../../../assets/img/project-page-img.png";
import BeatpageImg from "../../../assets/img/beat-page-img.png";
import TrackPageImg from "../../../assets/img/track-page-img.png";
import InfiniteImageScroll from "../../../common/infinite-image-scroll";
const view: FC<viewType> = ({ navigateTo }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2, // Adjust to 2, since you are using 2 rows and want to fit the grid
    speed: 500,
    rows: 3, // 2 rows of slides
    slidesPerRow: 1, // Display 2 slides per row
    autoplay: false, // Enable autoplay
    autoplaySpeed: 1000, // Time between slides in ms
    arrows: false,
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  return (
    <div className=" marketplaceProduct-main">
      <div className="row marketplaceProduct-main-firstrow">
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 marketplaceProduct-main-firstrow-col1 px-0">
          <div className="marketplaceProduct-main-firstrow-col1-content">
            <Heading0 className="text-wrap uni-mb-40">
              Easy.
              <br />
              Simple.
              <br />
              Different.
            </Heading0>
            <TextfieldSM className="uni-mb-40">
              Acquire shares from our range of music investment products, such
              as the <span className="gradient-text">DropWorks Select </span>or{" "}
              <span className="gradient-text-2">DropWorks Composite</span>,
              which feature a variety of hand-picked royalty distributing
              tracks.
            </TextfieldSM>
            <GradientActiveBtn className="w-auto uni-px-72">Explore our products</GradientActiveBtn>
          </div>
        </div>

        <div className=" col-12 col-sm-12 col-md-12 col-lg-6  px-0">
          <InfiniteImageScroll />
        </div>
      </div>

      <div className="marketplaceProduct-main-secondrow mx-auto uni-mb-56">
        <Heading3 className="uni-mb-56">Trade and grow your portfolio</Heading3>
        <TextfieldMD className="my-0 marketplaceProduct-main-secondrow-subtitle">
          Join the marketplace.
          <br />
          Buy, sell, or trade shares in our vibrant marketplace. As a
          shareholder, you’re not just enjoying great music, you’re also part of
          its ongoing journey. Watch as the value of your shares evolves based
          on the popularity and demand of <br />
          the tracks.{" "}
        </TextfieldMD>
      </div>

      <div className="marketplaceProduct-main-thirdrow mx-auto">
        <Tabs
          tabsTitle={["DropWorks Select", "DropWorks Composite"]}
          className="uni-mb-40 mx-auto marketplaceProduct-main-thirdrow-tab"
          checkTabState={checkTabState}
        />

        <TabsComponent
          tabsValue={tabsValue}
          components={[
            <>
              <div className="marketplaceProduct-main-thirdrow-content uni-mt-96">
                <div>
                  <div className="marketplaceProduct-main-thirdrow-content-box"></div>
                </div>
                <div className="marketplaceProduct-main-thirdrow-content-title">
                  <Heading4 className="uni-mb-56">
                    Your favorite tracks, reimagined
                  </Heading4>
                  <TextfieldMD className="uni-mb-56 mt-0">
                    Dropworks Select features popular songs from established
                    artists, now available on Dropworks. Own and enjoy music
                    that’s already making waves.
                  </TextfieldMD>
                  <div className="d-flex">
                    <DropworksNormalButton className="uni-mr-56" onClick={navigateTo("/marketplace-dropworks-select")}>
                      Learn more!
                    </DropworksNormalButton>
                    <DropworksNormalButton className="marketplaceProduct-main-thirdrow-content-title-tradebtn" onClick={navigateTo("/dropworks-select")}>
                      Trade now!
                    </DropworksNormalButton>
                  </div>
                </div>
              </div>
            </>,

            <>
              <div className="marketplaceProduct-main-thirdrow-content uni-mt-96">
                <div>
                  <div className="marketplaceProduct-main-thirdrow-content-box"></div>
                </div>
                <div className="marketplaceProduct-main-thirdrow-content-title">
                  <Heading4 className="uni-mb-56">
                    Discover exclusive music from our community
                  </Heading4>
                  <TextfieldMD className="uni-mb-56 mt-0">
                    Dropworks Composite offers a curated selection of original
                    tracks created by our artists and users. Enjoy fresh sounds
                    while owning a piece of the music.
                  </TextfieldMD>
                  <div className="d-flex">
                    <DropworksNormalButton className="uni-mr-56" onClick={navigateTo("/marketplace-dropworks-composite")}>
                      Learn more!
                    </DropworksNormalButton>
                    <DropworksNormalButton className="marketplaceProduct-main-thirdrow-content-title-tradebtn2" onClick={navigateTo("/dropworks-select-composite")}>
                      Trade now!
                    </DropworksNormalButton>
                  </div>
                </div>
              </div>
            </>,
          ]}
        />
      </div>
    </div>
  );
};

export default view;
