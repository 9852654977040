import { FC, useState } from "react";
import "./styles.scss";
import Tabs from "../../../common/tabs";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import OffNotification from "../../../assets/steps/muted icon.svg";
import { HiDotsHorizontal } from "react-icons/hi";
import { DropworksMessageInput } from "../../../common/custom-field/dropworks-input-messagebox";
import { ArrowDropdown } from "../../../common/icons";
import TabsComponent from "../../../common/tabs-component/view";
import { Heading2 } from "../../../common/custom-field/dropstock-heading";

export const view: FC<any> = () => {
  const [chatHistory, setChatHistory] = useState([
    {
      actionType: 1,
      message: "We have Recieved Message",
      time: "15:46",
    },
    { actionType: 0, message: "We have Sent Message", time: "15:46" },
  ]);
  const [show, setShow] = useState(false);

  const sendMessage = (message: string) => {
    console.log(message, "send message");
    const newMessage = {
      actionType: 0,
      message: message,
      time: new Date().toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };

    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
  };

  const click = () => {
    setShow(!show);
  };

  const [dropdown, setdropDown] = useState(false);
  const dropclick = () => {
    setdropDown(!dropdown);
  };

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    console.log(value, "value of tab in message");
    setTabValue(value);
  };

  const allChat = [
    [
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: true,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl:
          "https://i0.wp.com/picjumbo.com/wp-content/uploads/pure-nature-landscape-single-tree-in-green-field-free-photo.jpg?w=600&quality=80",
        name: "Project Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Project",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
    ],
    [
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
      {
        imgUrl: "",
        name: "Artist Name",
        seen: false,
        date: "8/14/2024",
        time: "15:46",
        type: "Artist",
        text: "Message example that artists may send to each other",
        allowNotification: true,
      },
    ],
    [
      {
        imgUrl:
          "https://img.freepik.com/free-photo/abstract-autumn-beauty-multi-colored-leaf-vein-pattern-generated-by-ai_188544-9871.jpg",
        name: "Project Name",
        seen: true,
        date: "8/14/2024",
        time: "15:46",
        type: "Project",
        text: "Message example that artists may send to each other",
        allowNotification: false,
      },
    ],
  ];

  const ErrorImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";

  const [chattingTab, setChattingTab] = useState("");
  return (
    <div className="newmessage container pb-3 px-0">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 w-100 ">
        <div className="d-flex">
          <div className="newmessage-left">
            <Heading2 className="newmessage-left-heading uni-mb-24">Messages</Heading2>
            <Tabs
              tabsTitle={["All", "Unread", "Projects"]}
              className="uni-mb-24"
              checkTabState={checkTabState}
            />
            <DropworksSearchInput className="uni-mb-24" />
            <div className="newmessage-left-chatsection">
              <TabsComponent tabsValue={tabsValue} components={
                [<>{allChat[0].map((ele) => {
                  return (
                    <div
                      className="newmessage-left-chatsection-chat uni-mb-32 cursor-pointer"
                      onClick={() => setChattingTab(ele.type)}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16">
                        <p className="newmessage-left-chatsection-chat-heading ">
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown
                                className="px-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>,
                <>{allChat[1].map((ele) => {
                  return (
                    <div
                      className="newmessage-left-chatsection-chat uni-mb-32"
                      onClick={() => setChattingTab(ele.type)}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16">
                        <p className="newmessage-left-chatsection-chat-heading ">
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown
                                className="px-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>
                ,
                <>{allChat[2].map((ele) => {
                  return (
                    <div
                      className="newmessage-left-chatsection-chat uni-mb-32"
                      onClick={() => setChattingTab(ele.type)}
                    >
                      <img
                        src={ele.imgUrl}
                        className={
                          ele.type === "Artist"
                            ? "img-profilepic"
                            : "img-profilepic-project"
                        }
                        onError={(e) => (e.currentTarget.src = ErrorImg)}
                        alt="image"
                      />
                      <div className=" align-items-center uni-ml-16">
                        <p className="newmessage-left-chatsection-chat-heading ">
                          {ele.name}{" "}
                          <p>
                            {!ele.seen ? (
                              <p className="newmessage-left-chatsection-chat-time">
                                {ele.time}
                              </p>
                            ) : (
                              <p className="newmessage-left-chatsection-chat-date">
                                {ele.date}
                              </p>
                            )}
                          </p>
                        </p>
                        <p className="newmessage-left-chatsection-chat-text">
                          <div className="newmessage-left-chatsection-chat-text-long-text">
                            {ele.text}
                          </div>
                          <p className="d-flex align-items-center">
                            {!ele.allowNotification && (
                              <img
                                className="img-offNotification uni-mx-8"
                                src={OffNotification}
                              />
                            )}
                            {!ele.seen && (
                              <p
                                className={
                                  ele.allowNotification
                                    ? "notify-yellow-dot uni-ml-40"
                                    : "notify-yellow-dot "
                                }
                              ></p>
                            )}
                            {ele.seen && !ele.allowNotification && (
                              <ArrowDropdown
                                className="px-0"
                                arr={["Unmute", "Block", "Report", "Delete"]}
                              />
                            )}
                          </p>
                        </p>
                      </div>
                    </div>
                  );
                })}
                </>
              ]
                } />
             
            </div>
          </div>
          {chattingTab && (
            <div className="newmessage-right uni-px-24">
              <div className="newmessage-right-heading-content d-flex align-items-center">
                {" "}
                <img
                  src={
                    chattingTab === "Project"
                      ? "https://i0.wp.com/picjumbo.com/wp-content/uploads/pure-nature-landscape-single-tree-in-green-field-free-photo.jpg?w=600&quality=80"
                      : ""
                  }
                  className={
                    chattingTab === "Artist"
                      ? "img-profilepic"
                      : "img-profilepic-project"
                  }
                  onError={(e) => (e.currentTarget.src = ErrorImg)}
                  alt="image"
                />
                <p className="newmessage-right-heading-content-heading uni-mx-16">
                  {chattingTab} Name
                </p>
                <HiDotsHorizontal />
              </div>
              <div className="newmessage-right-chatting justify-content-end uni-py-32">
                <div className="newmessage-chat-history flex-column">
                  {chatHistory.map((items) => (
                    <>
                      {items.actionType === 1 ? (<>
               
                        <div className="newmessage-receiver">
                          {chattingTab === "Project" && (
                            <img
                              src={
                                chattingTab === "Project" ? "" : "ele.imgUrl"
                              }
                              className={
                                chattingTab === "Project"
                                  ? "img-profilepic uni-mr-8 "
                                  : "img-profilepic-project"
                              }
                              onError={(e) => (e.currentTarget.src = ErrorImg)}
                              alt="image"
                            />
                          )}
                            <p className="newmessage-receiver-chat-triangle"></p>
                          <div className="newmessage-receiver-message">
                            <span>{items.message}</span>
                            <span className="fs-gray3 uni-fw-400">
                              {items.time}
                            </span>
                          </div>
                        </div>
                        </>
                      ) : (
                        ""
                      )}
                      {items.actionType === 0 ? (
                        <>
                        <div className="newmessage-sender justify-content-end">
                          <div className="newmessage-sender-message ">
                            <span>{items.message}</span>
                            <span className="uni-fw-400">{items.time}</span>
                          </div>
                          <p className="newmessage-sender-chat-triangle"></p>
                        </div>
     
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
                <div className="newmessage-send-message">
                  <DropworksMessageInput
                    placeholder="Type Search"
                    message={sendMessage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};