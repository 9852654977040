import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import iconImg from "../../../../../assets/img/upload-icon.png";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
const view = () => {
  const options = [
    { value: "Spotify", label: "Spotify" },
    { value: "Apple Music", label: "Apple Music" },
    { value: "Deezer", label: "Deezer" },
    { value: "Tidal", label: "Tidal" },
    { value: "Instagram", label: "Instagram" },
    { value: "Youtube", label: "Youtube" },
  ];
  return (
    <div
      className="modal fade"
      id="InviteModal2"
      aria-hidden="true"
      aria-labelledby="InviteModal2"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header p-0">
            <h1 className="modal-title">Invitation</h1>
          </div>

          <div className="modal-body p-0">
            <TextfieldLG className="uni-mb-32 uni-mt-16 ctm-txt">
              Please define your invitation parameters
            </TextfieldLG>

            <TextfieldLG className="text-content mt-0 uni-mb-16">
              Role
            </TextfieldLG>

            <DropworksDisableButton className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Select"
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            <TextfieldLG className="text-content uni-mb-16 mt-0">
              Percentage Offered
            </TextfieldLG>
            <DropworksInput placeholder="Percentage %" className="uni-mb-32" />

            <TextfieldLG className="text-content uni-mb-16 mt-0">
              Message
            </TextfieldLG>
            <DropworksTextArea
              className="invite-textarea uni-mb-32"
              placeholder="Message (Optional)"
            />

            <TextfieldLG className="text-content uni-mb-16 mt-0">
              Preview
            </TextfieldLG>
            <TextfieldXSM className="uni-mb-16 mt-0 preview-text">
              You may optionally attach a audio file in this invitation
            </TextfieldXSM>

            <div className="inviteModal_uploadbox mx-0 my-0">
              <img src={iconImg} className="uploadicon" />
              <TextfieldSM className="text-content upload-text mx-0 my-0">
                Upload
              </TextfieldSM>
            </div>
          </div>
          <div className="modal-footer p-0 uni-mt-32">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Back
            </DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0">
              Invite
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
