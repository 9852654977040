import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="Modal1"
    aria-hidden="true"
    aria-labelledby="Modal1"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Starting out</h1>
        </div>
        
        <div className="modal-body ">
          <TextfieldLG className=" uni-mt-16 ctm-txt">Pick your roles </TextfieldLG>
          <TextfieldXSM className='uni-mb-56 select-atleast'>(Select at least one)</TextfieldXSM>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Investor</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Producer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Vocalist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Lyricist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Beat Maker</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Curator</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>
          <div className='d-flex  investor-box-parent'>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Cover Designer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>

        </div>  
        
        <div className="modal-footer p-0">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        <ButtonComponent id="Modal2">
        <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
        </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

