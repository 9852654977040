import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import king from "../../../../../assets/img/king.png";
import verfiedIcon from "../../../../../assets/steps/verified-badge.svg";
import { MdOutlineFileDownload } from "react-icons/md";
import download from "../../../../../assets/steps/download.svg";
import { ButtonComponent } from "../../logo-header/view";

const view = () => {
  return (
    <div
      className="modal fade"
      id="BlockedModal"
      aria-hidden="true"
      aria-labelledby="BlockedModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header uni-mb-40 p-0">
            <h1 className="modal-title">Block </h1>
          </div>
          <div className="modal-body p-0 uni-mb-56">
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <div className="block-profile-box"></div>
              <div>
                <h2 className="fw-700 displayname">
                  #DISPLAYNAME
                  <img src={verfiedIcon} alt="" className="uni-ml-8" />{" "}
                </h2>
                <p className="userName-text">@USERNAME</p>
              </div>
            </div>
            <ul>
              <li className="list-item uni-mb-24">
                <span className="list-text">
                  @USERNAME won’t be able to message you or find your profile
                  and content on Dropworks
                </span>
              </li>
              <li className="list-item uni-mb-24">
                <span className="list-text">
                  @USERNAME won’t be notified that you blocked them
                </span>
              </li>
              <li className="list-item uni-mb-24">
                <span className="list-text">
                  You can unblock @USERNAME at any time in settings
                </span>
              </li>
            </ul>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksSmallButton>
            <ButtonComponent id="SuccessfullyBlocked">
            <DropworksSmallButton className="btn-wid m-0">
              Block
            </DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
