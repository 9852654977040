import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { DropworksSelect } from "../../../../../common/custom-field/dropworks-select";

const view = () => {
  return (
    <div
      className="modal fade"
      id="DisplayLanguageModal"
      aria-hidden="true"
      aria-labelledby="DisplayLanguageModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header p-0">
            <h1 className="modal-title">Display Language</h1>
          </div>

          <div className="modal-body p-0">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">
              Select your preferred language
            </TextfieldLG>
            <DropworksSelect className="uni-mb-56">
              <option value="">Language</option>
            </DropworksSelect>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid m-0">
              Save
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;


