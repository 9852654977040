import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import kingImage from "../../../../../assets/img/king.png";
import { Heading4 } from "../../../../../common/custom-field/dropstock-heading";

const view = () => {
  return (
    <div
      className="modal fade"
      id="SubMissionThankyou"
      aria-hidden="true"
      aria-labelledby="SubMissionThankyou"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header p-0 uni-mb-40">
            <Heading4 className="approve-modal-title mx-0 my-0">
              Thank you for your submission!
            </Heading4>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            {/* <TextfieldLG className='approve-modal-subtitle uni-mb-32 mt-0'>Doing so will permanently remove this file from the project</TextfieldLG> */}
            <TextfieldSM className="text-g3 mx-0 my-0 Notify-submission">
              We will notify you once a decision has been made.<br/>Thank you for
              your support for DropWorks!
            </TextfieldSM>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid m-0">
            Confirm
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
