import React, { useState } from "react";
import { FC } from "react";
import "./styles.scss";
import { IoSettingsSharp } from "react-icons/io5";
import { viewType } from "./modal";
import {
  TextfieldLG,
  TextfieldSM,
} from "../../../common/custom-field/dropstock-text";
import { FaChevronRight } from "react-icons/fa6";
const view: FC<viewType> = ({ navigateTo }) => {
  const [currentSection, setCurrentSection] = useState("Announcements");
  const [status, setStatus] = useState("");
  const [showMore, setshowMore] = useState({
    showMore1: false,
    showMore2: false,
    showMore3: false,
    showMore4: false,
    showMore5: false,
  })

  const shortText = "Deposit successfully received successfully received successfully received successfully received successfully received successfully received successfully received successfully received succ...";
  const longText = "Deposit successfully received successfully received successfully received successfully received successfully received successfully received successfully received successfully received succe"
  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
    setStatus("");
  };

  return (
    <div className="invite container-1408 container min-height-cover">
      <h1 className="invite-heading uni-mb-56">
        Notifications <IoSettingsSharp className="uni-ml-16 uni-fs-24" onClick={navigateTo("/profile-setting")} />
      </h1>
      <div className="invite-button uni-mb-56 d-flex flex-wrap">
        <button
          className={`invite-button-all uni-mr-8 ${currentSection === "Announcements" ? "active" : ""
            }`}
          onClick={() => handleSectionClick("Announcements")}
        >
          Announcements
        </button>
        <button
          className={`invite-button-all  uni-mr-8  ${currentSection === "Wallet" ? "active" : ""
            }`}
          onClick={() => handleSectionClick("Wallet")}
        >
          Wallet
        </button>
        <button
          className={`invite-button-all  uni-mr-8  ${currentSection === "Offers" ? "active" : ""
            }`}
          onClick={() => handleSectionClick("Offers")}
        >
          Offers
        </button>
        <button
          className={`invite-button-all  uni-mr-8  ${currentSection === "Marketplace" ? "active" : ""
            }`}
          onClick={() => handleSectionClick("Marketplace")}
        >
          Marketplace
        </button>
        <button
          className={`invite-button-all  uni-mr-8  ${currentSection === "Favorites" ? "active" : ""
            }`}
          onClick={() => handleSectionClick("Favorites")}
        >
          Favorites
        </button>
      </div>

      <div className="uni-py-24 uni-fs-700 notify-section">
        <p className="d-flex justify-content-between uni-mb-8">
          <p className="d-flex align-items-center notify-subheading  justify-content-start">
            Deposit Received <span className="notify-yellow-dot uni-ml-8"></span>
          </p>
          <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
        </p>
        <p className="d-flex justify-content-between uni-mb-8">
          <p>

            {!showMore.showMore1 && <p className="d-flex align-items-center uni-fs-400 fc-g3  ellipsis-icon   justify-content-start">
              {shortText}
            </p>}
            {showMore.showMore1 && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
              return <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                {longText}
              </p>
            })}

          </p>
          <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
            <FaChevronRight className="cursor-pointer" onClick={navigateTo("/new-wallet")}/>
          </p>
        </p>
        <p className="d-flex ">
          <span className="notify-show-more uni-fw-400 " onClick={() => setshowMore({ ...showMore, showMore1: !showMore.showMore1 })}>
            {showMore.showMore1 ? 'Show Less' : 'Show More'}
          </span>
        </p>
      </div>

      <div className="uni-py-24 uni-fs-700 notify-section">
        <p className="d-flex justify-content-between uni-mb-8">
          <p className="d-flex align-items-center notify-subheading  justify-content-start">
            Deposit Received
          </p>
          <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
        </p>
        <p className="d-flex justify-content-between uni-mb-8">
          <p>

            {!showMore.showMore2 && <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
              {shortText}
            </p>}
            {showMore.showMore2 && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
              return <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                {longText}
              </p>
            })}

          </p>
          <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
            <FaChevronRight className="cursor-pointer" onClick={navigateTo("/new-wallet")}/>
          </p>
        </p>
        <p className="d-flex ">
          <span className="notify-show-more uni-fw-400 " onClick={() => setshowMore({ ...showMore, showMore2: !showMore.showMore2 })}>
            {showMore.showMore2 ? 'Show Less' : 'Show More'}
          </span>
        </p>
      </div>

      <div className="uni-py-24 uni-fs-700 notify-section">
        <p className="d-flex justify-content-between uni-mb-8">
          <p className="d-flex align-items-center notify-subheading  justify-content-start">
            Deposit Received
          </p>
          <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
        </p>
        <p className="d-flex justify-content-between uni-mb-8">
          <p>

            {!showMore.showMore3 && <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
              {shortText}
            </p>}
            {showMore.showMore3 && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
              return <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                {longText}
              </p>
            })}

          </p>
          <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
            <FaChevronRight className="cursor-pointer" onClick={navigateTo("/new-wallet")}/>
          </p>
        </p>
        <p className="d-flex ">
          <span className="notify-show-more uni-fw-400 " onClick={() => setshowMore({ ...showMore, showMore3: !showMore.showMore3 })}>
            {showMore.showMore3 ? 'Show Less' : 'Show More'}
          </span>
        </p>
      </div>
      <div className="uni-py-24 uni-fs-700 notify-section-2">
        <p className="d-flex justify-content-between uni-mb-8">
          <p className="d-flex align-items-center notify-subheading  justify-content-start">
            Deposit Received
          </p>
          <p className="uni-fs-400 fc-g5 d-flex ">09 apr</p>
        </p>
        <p className="d-flex justify-content-between uni-mb-8">
          <p>

            {!showMore.showMore4 && <p className="d-flex align-items-center uni-fs-400 fc-g3   ellipsis-icon  justify-content-start">
              {shortText}
            </p>}
            {showMore.showMore4 && [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ele) => {
              return <p className="d-flex align-items-center uni-fs-400 fc-g3  justify-content-start">
                {longText}
              </p>
            })}

          </p>
          <p className="uni-fs-400 align-items-center fc-g5 d-flex uni-ml-16">
            <FaChevronRight className="cursor-pointer" onClick={navigateTo("/new-wallet")}/>
          </p>
        </p>
        <p className="d-flex ">
          <span className="notify-show-more uni-fw-400 " onClick={() => setshowMore({ ...showMore, showMore4: !showMore.showMore4 })}>
            {showMore.showMore4 ? 'Show Less' : 'Show More'}
          </span>
        </p>
      </div>

    </div>
  );
};

export default view;
