import { FC, useEffect, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksNormalButton,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import Tabs from "../../../common/tabs";
import { DropworksSelect } from "../../../common/custom-field/dropworks-select";
import { DropworksSwitch } from "../../../common/custom-field/dropworks-switch";
import kingRoland from "../../../assets/img/roland.png";
import { BlueTik, YelloTik } from "../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../common/icons";
import king from "../../../assets/img/king.png";
import downloadIcon from "../../../assets/steps/download.svg";
import Producer from "../../../assets/img/Producer.png";
import { UserLable } from "../../../common/user-labels";
import treeImage from "../../../assets/img/tree.png";
import rightArrow from "../../../assets/steps/arrow-small-right.svg";
import exclamationTriangle from "../../../assets/steps/exclamation-triangle.svg";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import TabsComponent from "../../../common/tabs-component/view";
const view: FC<viewType> = ({ navigateTo }) => {
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
   "Change Alias"
  ]);
  const [MeatballsMenuOptions2, setMeatballsMenuOptions2] = useState([
    "Change Alias",
    "Message",
    "Remove"
   ]);

   const [MeatballsMenuOptions3, setMeatballsMenuOptions3] = useState([
    "Delete",
    "Approve",
    "Decline"
   ]);
  const [tabsValue, setTabValue] = useState(0);
  const [showComponent, setShowComponent] = useState(tabsValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 300); // Matches the CSS transition duration

    return () => clearTimeout(timeout);
  }, [tabsValue]);

  return (
    <div className="container container-xxl min-height-cover">
      <h1 className="uni-mb-24">Project Name</h1>
      <Tabs
        tabsTitle={["Overview", "Collaborators", "File Center", "Release"]}
        className="uni-mb-40"
        checkTabState={checkTabState}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0">
                <h2 className="uni-mb-16">Overview</h2>
                <TextfieldLG>
                  Fill out the general information about this track.{" "}
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40">
                  Note that you can navigate freely between sections at any
                  given time.
                </TextfieldSM>

                <TextfieldLG className="uni-mb-16 content-sunheading">
                  Beat Name
                </TextfieldLG>
                <DropworksInput placeholder="Name" className="uni-mb-32" />
                <TextfieldLG className="uni-mb-16 content-sunheading">
                  Description
                </TextfieldLG>
                <DropworksTextArea
                  className="uni-mb-32"
                  placeholder="Description (Optional)"
                  rows={2}
                />
                <TextfieldLG className="uni-mb-16 content-sunheading">
                  Genre
                </TextfieldLG>
                <DropworksSelect className="uni-mb-32"></DropworksSelect>
                <TextfieldLG className="uni-mb-16 content-sunheading">
                  Language
                </TextfieldLG>
                <DropworksSelect className="uni-mb-32"></DropworksSelect>

                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Contains Explicit Language
                  </TextfieldSM>
                  <DropworksSwitch />
                </div>
                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Make this project public
                  </TextfieldSM>
                  <DropworksSwitch />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
                <TextfieldLG>Artwork</TextfieldLG>

                <div className="beatEmpty_uploadbox2 flex-column mb-3">
                  <ButtonComponent id="beatartwork">
                    <img src={iconImg} alt="Upload Icon" />
                  </ButtonComponent>
                  <label className="mt-4"> Track Artwork</label>
                </div>
              </div>
            </div>
          </>,
          <>
            <div className="">
              <h2 className="uni-mb-16">Collaborators</h2>
              <TextfieldLG>Manage your team of collaborators here.</TextfieldLG>
              <TextfieldSM className="fg-color uni-mb-40">
                Note that you can navigate freely between sections at any given
                time.
              </TextfieldSM>

              <div className="d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>
                    Producer <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img src={king} className="img-king uni-mr-16" alt="" />
                    <div className="uni-mr-16">
                      <h4 className="king-roland-text">
                        King Roland <BlueTik />
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksSelect className="select-track uni-mr-16">
                      <option>Credit</option>
                    </DropworksSelect>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions}
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>
                    Vocalist <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <h4 className="king-roland-text">
                        King Roland <BlueTik />
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksSelect className="select-track uni-mr-16">
                      <option>Credit</option>
                    </DropworksSelect>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2 className="king-roland-text">
                    Beat Maker <YelloTik />
                  </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <h4>
                        King Roland <BlueTik />
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksSelect className="select-track uni-mr-16">
                      <option>Credit</option>
                    </DropworksSelect>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2 className="king-roland-text">Lyricist </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <h4>
                        King Roland <BlueTik />
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksSelect className="select-track uni-mr-16">
                      <option>Credit</option>
                    </DropworksSelect>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between align-items-center collaborators-content uni-mb-32">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <h2>Artwork Designer </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <h4 className="king-roland-text">
                        King Roland <BlueTik />
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksSelect className="select-track uni-mr-16">
                      <option>Credit</option>
                    </DropworksSelect>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                    ></MeatballsMenuDropdown>
                  </div>
                </div>

                <div className="collaborators-content-under pe-0 uni-mb-32">
                  <h2>Curator </h2>
                  <div className="d-flex align-items-center uni-py-16">
                    <img
                      src={kingRoland}
                      className="img-king uni-mr-16"
                      alt=""
                    />
                    <div className="uni-mr-16">
                      <h4 className="king-roland-text">
                        King Roland <BlueTik />
                      </h4>
                    </div>
                    <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                      55%
                    </DropworksNormalButton>
                    <DropworksSelect className="select-track uni-mr-16">
                      <option>Credit</option>
                    </DropworksSelect>
                    <MeatballsMenuDropdown
                      arr={MeatballsMenuOptions2}
                    ></MeatballsMenuDropdown>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-between">
                <div className=" collaborators-content-under pe-0 uni-mb-32">
                  <ButtonComponent id="SearchInvite">
                    <div className="plus-icon">+</div>
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </>,
          <>
            <h2 className="uni-mb-16">File Center</h2>
            <div className="d-flex">
              <div className="uni-mr-56">
                <TextfieldLG className="mt-0">
                  Manage all files uploaded here.
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40">
                  Note that you can navigate freely between sections at any
                  given time.
                </TextfieldSM>
              </div>
              <ButtonComponent id="UploadModal">
                <DropworksNormalButton>Upload</DropworksNormalButton>
              </ButtonComponent>
            </div>
            <div className="d-flex justify-content-between file-center-main align-items-center uni-mb-32">
              <div className="producer-main">
                <h2 className="uni-mb-16">
                  Producer <YelloTik />{" "}
                </h2>

                <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                  <img src={Producer} className="img-king" alt="" />
                  <div>
                    <h2 className="fw-700">
                      King Roland <BlueTik />{" "}
                    </h2>
                  </div>
                </div>
                <div className="producer-border">
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="producer-main">
                <h2 className="uni-mb-16">
                  Vocalist <YelloTik />{" "}
                </h2>

                <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                  <img src={Producer} className="img-king" alt="" />
                  <div>
                    <h2 className="fw-700">
                      King Roland <BlueTik />{" "}
                    </h2>
                  </div>
                </div>
                <div className="producer-border">
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between uni-mb-32">
              <div className="producer-main">
                <h2 className="uni-mb-16">
                  Beat Maker <YelloTik />{" "}
                </h2>

                <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                  <img src={Producer} className="img-king" alt="" />
                  <div>
                    <h2 className="fw-700">
                      King Roland <BlueTik />{" "}
                    </h2>
                  </div>
                </div>
                <div className="producer-border">
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="producer-main">
                <h2 className="uni-mb-16">Lyricist </h2>

                <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                  <img src={Producer} className="img-king" alt="" />
                  <div>
                    <h2 className="fw-700">
                      King Roland <BlueTik />{" "}
                    </h2>
                  </div>
                </div>
                <div className="producer-border">
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="producer-main">
                <h2 className="uni-mb-16">Artwork Designer </h2>

                <div className="d-flex align-items-center uni-mb-16 invite_firstcolumn">
                  <img src={Producer} className="img-king" alt="" />
                  <div>
                    <h2 className="fw-700">
                      King Roland <BlueTik />{" "}
                    </h2>
                  </div>
                </div>
                <div className="producer-border">
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                  <hr className="producer-hr" />
                  <div className="d-flex align-items-center justify-content-between uni-mt-16">
                    <div>
                      <TextfieldLG className="fs-color-g3">
                        Type:<span className="fw-400-wh"> Final track</span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Description:
                        <span className="fw-400-wh">
                          {" "}
                          Finalized track for release
                        </span>
                      </TextfieldLG>
                      <TextfieldLG className="fs-color-g3">
                        Date:{" "}
                        <span className="fw-400-wh"> mm/dd/yyyy hh:mm</span>
                      </TextfieldLG>
                    </div>
                    <div className="d-flex">
                      <h5>
                        <img src={downloadIcon} alt="" />
                      </h5>
                      <h5>
                        <MeatballsMenuDropdown
                          arr={MeatballsMenuOptions3}
                        ></MeatballsMenuDropdown>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>,
          <>
            <TextfieldLG>
              Remember to review everything before releasing the track.{" "}
            </TextfieldLG>
            <TextfieldSM className="fg-color uni-mb-40">
              {" "}
              Note that you can navigate freely between sections at any given
              time.
            </TextfieldSM>

            <div className="d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <img src={treeImage} className="uni-mr-24" />
                <div>
                  <h1 className="fs-56">Hot Summer </h1>
                  <TextfieldMD className="fc-gray4">
                    Unlost, Uncovered, Meyna, Maluma
                  </TextfieldMD>
                  <UserLable>EDM</UserLable>
                </div>
              </div>
              <ButtonComponent id="ReleaseModal">
                <DropworksNormalButton className="release-btn">
                  Release
                </DropworksNormalButton>
              </ButtonComponent>
            </div>
            <hr className="uni-my-40" />
            <div className="uni-p-40">
              <h2 className="missing-text uni-mb-24">
                <img src={exclamationTriangle} className="uni-mr-16" />
                Missing Steps
              </h2>
              <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Credit all collaborators <img src={rightArrow} />
              </TextfieldLG>
              <TextfieldLG className="fc-gray4 uni-mr-8 uni-mb-16 mt-0">
                Upload the final track file <img src={rightArrow} />
              </TextfieldLG>
              <TextfieldLG className="fc-gray4 uni-mr-8 mt-0 mb-0">
                Fill all the base information of the track{" "}
                <img src={rightArrow} />
              </TextfieldLG>
            </div>
          </>,
        ]}
      />
    </div>
  );
};
export default view;
