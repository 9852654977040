import { FC } from "react"
import { viewType } from "./modal"
import "./styles.scss";
import {FaXTwitter} from 'react-icons/fa6';
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { FcGoogle } from "react-icons/fc";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";

export const view :FC<viewType> = ({navigateTo}) => {
  return (
    <div className="recemail page-content row align-items-center container pb-3 m-auto h-100 px-0">
    <div className="recemail_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
    <h1 className="recemail_heading my-0">Recover Account</h1>
      <TextfieldSM className="recemail_normal ">
      Please provide your accounts email address
      </TextfieldSM>
      <TextfieldXSM className="recemail_privacy pb-0 sign-up-bottom">We will send you a recovery email to your inbox, remember to check the spam folder.</TextfieldXSM>
      <form>
        <DropworksInput className="recemail-bottom uni-mt-32  " placeholder="Email"/>
        <DropworksButton className="uni-mt-16" onClick={navigateTo("/recover-account")}>Recover</DropworksButton>
        <p className="recemail_already_registered uni-my-32 mb-22px sign-up-top  fs-6">Don’t have an account?   <span className="recemail_logins"  onClick={navigateTo("/sign-up")}>Register</span></p>
        <p className="recemail_already_registered  fs-6">Already registered? <span className="recemail_logins"  onClick={navigateTo("/login")}> Login   </span></p>
      </form>
    </div>
  </div>
  )
}

