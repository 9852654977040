export const getLocalStorageObject = (key: string) => {
    const json = localStorage.getItem(key);
    if (!json || json === 'undefined') {
        return undefined;
    }

    return JSON.parse(json);
};

export const setLocalStorageObject = (key: string, object: unknown): void => {
    const json = JSON.stringify(object);
    localStorage.setItem(key, json);
};

export const removeLocalStorageObject = (key: string): void => {
    localStorage.removeItem(key);
};
