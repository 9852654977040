import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";

const view = () => {
  return (
    <div
    className="modal fade"
    id="EmailModal6"
    aria-hidden="true"
    aria-labelledby="EmailModal6"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
        <div className="modal-header p-0">
          <h1 className="modal-title">Confirm Your New Email</h1>
        </div>
        
        <div className="modal-body p-0">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Please confirm the code we’ve sent you</TextfieldLG>
          <DropworksInput className="mb-0" placeholder="Code" />
          <TextfieldXSM className="ctm-txt2 uni-mb-56">Resend Code</TextfieldXSM>
        </div>  
        <div className="modal-footer p-0">
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> <DropworksSmallButton  className="btn-wid m-0">Finish</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


