import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";

const view = () => {
  return (
    <div
      className="modal fade"
      id="beatartwork"
      aria-hidden="true"
      aria-labelledby="beatartwork"
      tabIndex={-1}
      data-bs-theme="dark"
    >             
      <div className="modal-dialog modal-dialog-centered custom-modal-width m-auto ">
        <div className="modal-content modal-width-upload-banner">
          <div className="modal-header p-0">
            <h1 className="modal-title">Upload Beat Artwork</h1>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-56">Use a square high resolution picture</TextfieldLG>
            <div className="uploadBanner_uploadbox mx-0 my-0">
              <img src={iconImg} className='uploadBanner-uploadicon' />
              <TextfieldSM className='text-content uploadBanner-upload-text mx-0 my-0'>Upload</TextfieldSM>
            </div>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">Back</DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0">Save</DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

