import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';

const view = () => {
  return (
    <div
    className="modal fade"
    id="BiographyModal"
    aria-hidden="true"
    aria-labelledby="BiographyModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
        <div className="modal-header p-0">
          <h1 className="modal-title">Biography</h1>
        </div>
        
        <div className="modal-body p-0">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">Bio that will be displayed on your profile</TextfieldLG>
          <DropworksTextArea className="uni-mb-56 ctm-textarea" placeholder="Biography" />
        </div>  
        <div className="modal-footer p-0">
          <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


