import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksLargeButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import hankeymen from "../../../../../assets/img/hankeyman.png";
import tree from "../../../../../assets/img/tree.png";

const view = () => {
  return (
    <div
      className="modal fade"
      id="SellDropStocks3"
      aria-hidden="true"
      aria-labelledby="SellDropStocks3"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width selldropstock3-modal ">
        <div className="modal-content">
          <div className="modal-header p-0 uni-mb-32">
            <h1 className="approve-modal-title mx-0 my-0">Confirm?</h1>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            <TextfieldSM className="sell-modal-subtitle uni-mb-32">
            You are about to sell your Dropstocks. This action is permanent and non-reversible.
            </TextfieldSM>
            <div className="d-flex align-items-center uni-mb-32">
              <img className="img-fluid custom-tree-img uni-mr-16" src={tree} />
              <h4 className="">
                {" "}
                <span className="sellDropstock-modal-text">Hot Summer</span>
                <TextfieldMD className="d-block m-0 sell-dropstocks-subpara">
                  Unlost, Uncovered, Meyna, Maluma
                </TextfieldMD>
              </h4>
            </div>
            <TextfieldSM className="sell-note-3">Please note that you will be selling <span className="sell-dropstock-highlight">800 Dropstocks</span> and will receive <span className="sell-dropstock-highlight">$960.00</span> in your account balance.</TextfieldSM>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3-approve my-0 ml-0">Cancel</DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0">Confirm</DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
