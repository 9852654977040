import { FC, useState } from "react";
import { view } from "./view";
interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
    arr: { heading: string, option: string[] };
}

export const DropworksDropdown:FC<DropdownProps>=({...props})=>{
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  
    const handleRoleChange = (role: string) => {
        setSelectedRoles((prev) =>
            prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
        );
    };
    return view({selectedRoles,handleRoleChange,...props});
}