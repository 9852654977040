import { DropworksButton } from "../../../common/custom-field/dropworks-button";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import AccountSecured from "../../shared/components/pop-ups/acc-secured";
import AcceptedInviteReceived from "../../shared/components/pop-ups/accepted-invite-received";
import "./styles.scss";

export const view = () => {
  return (
    <>
      <div className="container  m-auto">
        <div className="row uni-my-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Account Secured"
              id="acc-secured"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Secured Account"
              id="SecureYourAccount"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Social Link-8"
              id="SocialLink8"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Social Link-4"
              id="SocialLink14"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Social Link-9"
              id="SocialLink9"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SocialLink-13"
              id="SocialLink13"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SocialLink1-10"
              id="SocialLink10"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SocialLink1-12"
              id="SocialLink12"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Social Link-8"
              id="SocialLink8"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SocialLink-11"
              id="SocialLink11"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Social Link-9"
              id="SocialLink9"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="EmailModal-1"
              id="EmailModal1"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="EmailModal-2"
              id="EmailModal2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="EmailModal-6"
              id="EmailModal6"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="MobilePhone-3"
              id="MobilePhoneModal3"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="MobilePhone-A"
              id="MobilePhoneModalA"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="MobilePhone-B"
              id="MobilePhoneModalB"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ProfilePicture"
              id="ProfilePictureModal"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ProfilePicture-2"
              id="ProfilePictureModal2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DisplayName"
              id="DisplayName"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="BiographyModal"
              id="BiographyModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SecurityQuestion"
              id="SecurityQuestion"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SecurityQuestion-2"
              id="SecurityQuestion2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ChangePassword"
              id="ChangePassword"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="InviteModal-2"
              id="InviteModal2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Modal-2"
              id="Modal2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeleteAccount"
              id="DeleteAccount"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeleteAccount-3"
              id="DeleteAccount3"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="RegionModal"
              id="RegionModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DisplayLanguage"
              id="DisplayLanguageModal"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ActiveReceived"
              id="ActiveInviteReceived"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="AcceptedReceived"
              id="AcceptedInviteReceived"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeclinedReceived"
              id="DeclinedInviteReceived"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ExpiredReceived"
              id="ExpiredInviteReceived"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="AcceptedSent"
              id="AcceptedInviteSent"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ActiveSent"
              id="ActiveInviteSent"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeclinedSent"
              id="DeclinedInviteSent"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ExpiredSent"
              id="ExpiredInviteSent"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ReportModal-1"
              id="ReportModal1"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Impersonating"
              id="ImpersonatingReportModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="RolesModal"
              id="RolesModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DawsModal"
              id="DawsModal"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ReportChat"
              id="ReportRecentChatModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SuccessfullBlocked"
              id="SuccessfullyBlocked"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ThankYouModal"
              id="ThankYouModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="UserReportReason"
              id="UserReportReasonModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="BeatReportModal"
              id="BeatReportModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="InviteModal"
              id="InviteModal"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="EmailModal"
              id="EmailModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="BlockedUser-1"
              id="BlockedUser1"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SocialNotification"
              id="SocialLinksNotification"
            />
          </div>

          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="LoginHistorySocials"
              id="LoginHistorySocials"
            />
          </div>

          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="BlockedUser-2"
              id="BlockedUser2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="BlockedModal"
              id="BlockedModal"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Modal-1"
              id="Modal1"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SelectTrack"
              id="SelectTrack"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SelectBeat"
              id="SelectBeat"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="UploadBanner"
              id="UploadBanner"
            />
          </div>

          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="UploadBanner2"
              id="UploadBanner2"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SocialLinksMain"
              id="SocialLinksMain"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Beat-Art-work"
              id="beatartwork"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Release-Modal"
              id="ReleaseModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SelectBeat"
              id="SelectBeat"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="UploadBanner"
              id="UploadBanner"
            />
          </div>

          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ApproveFile"
              id="ApproveFile"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeclineFile"
              id="DeclineFile"
            />
          </div>
        </div>

        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="UploadModal"
              id="UploadModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Deposit-Modal"
              id="DepositModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Withdraw-Modal"
              id="WithdrawModal"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Invite-Modal"
              id="InviteModal8"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Search-Invite"
              id="SearchInvite"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Change-Alias"
              id="ChangeAlias"
            />
          </div>
        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="RemoveCollaborator"
              id="RemoveCollaborator"
            />
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="LoginHistory"
              id="LoginHistorySocials"
            ></ButtonComponent>
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeleteFile"
              id="DeleteFile"
            ></ButtonComponent>
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="DeleteChat"
              id="DeleteChat"
            ></ButtonComponent>
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ApproveFile-2"
              id="ApproveFile2"
            ></ButtonComponent>
          </div>
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Select-submit"
              id="SelectSubmit"
            ></ButtonComponent>
          </div>
      

        </div>
        <div className="row uni-mb-24">
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SubMissionThankyou"
              id="SubMissionThankyou"
            />
          </div>
           <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SellDropStocks"
              id="SellDropStocks"
            ></ButtonComponent>
          </div> 
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="SellDropStocks3"
              id="SellDropStocks3"
            ></ButtonComponent>
          </div>
           <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="Selectsubmit3"
              id="DropStocksSelectsubmit3"
            ></ButtonComponent>
          </div> 
          <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="CompositSubmit"
              id="CompositSubmit"
            ></ButtonComponent>
          </div> 
           <div className="col-lg-2">
            <ButtonComponent
              className="cstm-button-modal-test-modal"
              name="ReleaseModal"
              id="ReleaseModal"
            ></ButtonComponent>
          </div> 
      

        </div>
      </div>
    </>
  );
};
