import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';

const view = () => {
  return (
    <div
    className="modal fade"
    id="WithdrawModal"
    aria-hidden="true"
    aria-labelledby="WithdrawModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-deposit">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Withdraw Method</h1>
        </div>
        
        <div className="modal-body ">
          <TextfieldLG className=" uni-mt-16 uni-mb-56 ctm-txt">To proceed please select a withdraw method</TextfieldLG>
          <div className='d-flex investor-box-parent-deposit uni-mb-40 uni-mt-8 '>
               <div className='investor-boxs-deposit'>
                    <p className='investor-boxs-under-modal2'>Bank Transfer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs-deposit'>
                    <p className='investor-boxs-under-modal2'>Paypal</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>
        </div>  
        
        <div className="modal-footer">
        <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

