import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";

const view = () => {
  return (
    <div
      className="modal fade"
      id="ChangePassword2"
      aria-hidden="true"
      aria-labelledby="ChangePassword2"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header p-0">
            <h1 className="modal-title uni-mb-56">Change Password</h1>
          </div>

          <div className="modal-body p-0">
            <DropworksPassword
              className="uni-mb-32 ctm-inp-color1"
              placeholder="Current Password"
            />
            <DropworksPassword
              className="uni-mb-24"
              placeholder="New Password"
            />
            <DropworksPassword
              className="uni-mb-56 ctm-inp-color2"
              placeholder="Confirm New Password"
            />
          </div>
            <div className="modal-footer p-0">
              <DropworksSmallButton className="btn-wid btn-gray3 my-0 ml-0 uni-mr-16 btn-bg-gray3">
                Back
              </DropworksSmallButton>{" "}
              <DropworksSmallButton className="btn-wid m-0 btn-bg-black2">
                Finish
              </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;

