import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss"
const view = () => {
  return (
    <div
    className="modal fade"
    id="ProfilePictureModal2"
    aria-hidden="true"
    aria-labelledby="ProfilePictureModal2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content mx-auto  w-auto">
        <div className="modal-header p-0">
          <h1 className="modal-title">Upload a Banner</h1>
        </div>
        
        <div className="modal-body w-476 p-0">
          <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">Try to use a 16:9 high resolution banner</TextfieldLG>
          <TextfieldXSM className="ctm-txt2 uni-mb-56">(Don’t upload anything if you do not want this do be displayed on your profile)</TextfieldXSM>
          {/* <DropworksInput className=" uni-mb-56" placeholder="Email" /> */}
          <div className='profile-pic-white-space-rectangle'>

          </div>
        </div>  
        <div className="modal-footer uni-mt-56 p-0">
        <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Cancel</DropworksSmallButton> <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


