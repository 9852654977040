import {
  TextfieldMD,
  TextfieldSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { FC } from "react";
import "./styles.scss";
import releaseImg from '../../../../../assets/img/release-img.png';

interface viewType {
  navigateTo:(path:string)=>()=>void
} 

const view :FC<viewType> =({ navigateTo }) => {
  return (
    <div
      className="modal fade"
      id="ReleaseModal"
      aria-hidden="true"
      aria-labelledby="ReleaseModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width-release">
        <div className="modal-content custom-modal-release">
          <div className="modal-header uni-mb-32 p-0">
            <h1 className="modal-title">Release Track</h1>
          </div>
          <div className="modal-body p-0 uni-mb-32">
             <TextfieldSM className="release-subheading uni-mb-32">You are about to re-release the final track. This action is final and non-reversible, so please make sure that all the information is correct.</TextfieldSM>
              
              <div className="d-flex align-items-center gap-3 uni-mb-32">
                <img src={releaseImg} alt="" />
                <div>
                  <h2 className="hot-summer">Hot Summer</h2>
                 <TextfieldMD className="hot-summer-under">Unlost, Uncovered, Meyna, Maluma</TextfieldMD>
                </div>
              </div>
              
             <TextfieldSM className="release-subheading uni-mb-32">We will release your Track under the same ISRC code, and shall notify you via email once we complete the process</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">This track will be split in 5000 <span className="dropstock-text"> DropStocks</span> that will be distributed among all the collaborators based on their ownership percentages.</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">Once the track is officially released, all owners will receive their <span className="dropstock-text"> DropStocks</span> in their accounts.All royalties will be received by the  <span className="dropstock-text"> DropStocks</span> owners directly into their balances.</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32"> Note that any Dropstock owner will be able to make a submission to apply this track to the DropWorks Select and possibly receive an offer directly from our team.</TextfieldSM>
             <TextfieldSM className="release-subheading uni-mb-32">Please note that after receiving the <span className="dropstock-text"> DropStocks</span>, all owners will be able to trade them in any platforms accepting NFT 2.0 Standard.</TextfieldSM>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0" >
              Release
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
