import { FC } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import { FcGoogle } from "react-icons/fc";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton, DropworksLargeButton, DropworksMediumButton, DropworksNormalButton, DropworksPrimaryButton, DropworksSecondaryButton, DropworksSmallButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";
import { Heading3, Heading5 } from "../../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../../shared/components/logo-header/view";

const view: FC<viewType> = ({ navigateTo }) => {
  
  return (
    <div className="signUp page-content row align-items-center container pb-3 m-auto h-100 px-0">
      <div className="signUp_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
      <h1 className="signUp_heading my-0">Welcome to DropWorks</h1>
        <TextfieldSM className="signUp_normal uni-my-32 ">
          Use one of the options below to register
        </TextfieldSM>
        <form>
          <DropworksInput  placeholder="Email"/>
          <DropworksPassword className="uni-mb-32"  />
          <DropworksButton className="my-0 signUp_register" onClick={navigateTo("/fill-details")}>Register</DropworksButton>
          <TextfieldSM className="signUp_or text-center uni-my-16">OR</TextfieldSM>
          <DropworksButton className="signUp_register_google uni-mb-32"> <FcGoogle className="signUp_icon" /> Sign up with Google</DropworksButton>
          <p className="signUp_already_registered  uni-mb-32 fs-6">Already registered? <span className="signUp_logins"  onClick={navigateTo("/login")}>Login</span></p>
          <TextfieldXSM className="signUp_privacy pb-0">By creating an account with DropWorks you agree with our <a className="signUp_privacy_link">Terms of Use</a>,<a className="signUp_privacy_link"> Privacy Policy </a> and <a className="signUp_privacy_link">Community Guidelines</a>.</TextfieldXSM>
        </form>
      </div>
    </div>
  );
};
export default view;
