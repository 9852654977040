import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import { DropworksButton, DropworksNormalButton } from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import { TextfieldLG, TextfieldSM, TextfieldXSM } from "../../../common/custom-field/dropstock-text";
const view: FC<viewType> = ({ navigateTo }) => {

  const [uploadedAudioFile, setUploadedAudioFile] = useState<File | null>(null);

  const handleAudioUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedAudioFile(file);
    }
  };
  const handleRemoveAudio = () => {
    setUploadedAudioFile(null);
  };


  const [uploadedArtwork, setUploadedArtwork] = useState<string | null>(null);

  const handleArtworkUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUploadedArtwork(imageUrl);
    }
  };

  const handleRemoveArtwork = () => {
    setUploadedArtwork(null);
  };
  return (

    <div className="container">
      <h1 className="mx-0 my-0 heading-beat-empty">Add a Beat</h1>
      <TextfieldLG className="subHeading-beat-empty uni-mt-16 uni-mb-56">Please fill all mandatory fields to release the beat</TextfieldLG>

      <div className="row d-flex justify-content-between">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0">
          <TextfieldLG className="text-fields-beat">Beat Name</TextfieldLG>
          <DropworksInput placeholder="Name" />
          <TextfieldLG className="text-fields-beat">Beat Name</TextfieldLG>
          <DropworksTextArea placeholder="Description (Optional)" rows={2} />
          <TextfieldLG className="text-fields-beat">Audio File</TextfieldLG>
          <TextfieldXSM className="text-fields-xsm-beat uni-mb-16 uni-mt-16">Allowed formats:<span className="font-yellow">.MP3, .WAV, .M4A</span> </TextfieldXSM>

          <div className="audio-upload-section mb-3">
            {uploadedAudioFile ? (
              <div className="audio-file-info d-flex align-items-center justify-content-between">
                <TextfieldSM className="file-name mx-0 my-0">
                  {(() => {
                    const fileName = uploadedAudioFile.name;
                    const extension = fileName.slice(fileName.lastIndexOf('.'));
                    const nameWithoutExtension = fileName.slice(0, fileName.lastIndexOf('.'));

                    return nameWithoutExtension.length > 10
                      ? `${nameWithoutExtension.slice(0, 10)}...${extension}`
                      : fileName;
                  })()}
                </TextfieldSM>

                <span className="remove-file-icon ml-2" onClick={handleRemoveAudio} >X</span>
              </div>
            ) : (
              <label htmlFor="audio-upload" className="upload-label">
                <div className="beatEmpty_uploadbox flex-column mb-3 ">
                  <img src={iconImg} />
                  <label className="mt-4">Beat Artwork</label>
                </div>
              </label>
            )}
            <input
              id="audio-upload"
              type="file"
              accept=".mp3, .wav, .m4a"
              onChange={handleAudioUpload}
              className="d-none"
            />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
          <TextfieldLG>Artwork</TextfieldLG>

          <div className="beatEmpty_uploadbox2 flex-column mb-3">
            <label htmlFor="file-upload" className={`${uploadedArtwork?"upload-label2":""}`}>
              {uploadedArtwork ? (
                <>
                  <img src={uploadedArtwork} alt="Uploaded Artwork" className="uploaded-artwork" />
                </>
              ) : (
                <img src={iconImg} alt="Upload Icon" />
              )}
            </label>
            {!uploadedArtwork && <label className="mt-4">Beat Artwork</label>}
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              className="d-none"
              onChange={handleArtworkUpload}
            />
          </div>
        </div>

      </div>
      <div className="d-flex justify-content-end uni-mt-56">
        <DropworksNormalButton className={`${uploadedArtwork?"beat-empty-button-done":"beat-empty-button"}`}>Release</DropworksNormalButton>
      </div>

    </div>
  );
};
export default view;



