import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';

const view = () => {
  return (
    <div
    className="modal fade"
    id="DawsModal"
    aria-hidden="true"
    aria-labelledby="DawsModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">DAWs</h1>
        </div>
        
        <div className="modal-body ">
          <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">Select the DAWs that you are familiar with </TextfieldLG>
          <TextfieldXSM className='ctm-daws-text'>Choose up to 2</TextfieldXSM>
          <TextfieldXSM className='ctm-daws-text uni-mb-56'>(Don’t select any if you do not want this do be displayed on your profile)</TextfieldXSM>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>FL Studio</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Ableton Live</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Logic Pro </p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
          </div>
          <div className='d-flex uni-mb-24 investor-box-parent'>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Pro Tools</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               <div className='investor-boxs'>
                    <p className='investor-boxs-under'>Cubase</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </div>
               
          </div>
          

        </div>  
        
        <div className="modal-footer p-0">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        
        <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

