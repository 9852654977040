import React, { FC } from "react";
import "./styles.scss";
import king from '../../assets/img/inviteimg.png';
import verfiedIcon from '../../assets/steps/verified-badge.svg';
import { ButtonComponent } from "../../app/shared/components/logo-header/view";
export const view: FC<any> = ({
    image,
    arr
}) => {
    return (
        <div className="row justify-content-between">
            {arr.map((data: any, index: any) => (

                <div className="invite-main  uni-p-16 pe-0 uni-mb-32">
                    <ButtonComponent id={data.id}>
                        <div className="d-flex align-items-center  invite_firstcolumn">
                            <img src={king} className="invite_firstcolumn_imagebox" alt="" />
                            <div>
                                <h2 className="fw-700">{data.name} <img src={verfiedIcon} alt="" className="uni-ml-8" /> </h2>
                                <p>{data.subname}</p>
                            </div>
                            <div className=" invite_firstcolumn_vocalist">{data.vocalist}</div>
                            <button className="invite_firstcolumn_percentage">{data.percentage}</button>
                            <button className={data.active === "Active" ? "invite_firstcolumn_activebtn-yel" : data.active === "Declined" ? "invite_firstcolumn_activebtn-red" : data.active === "Accepted" ? "invite_firstcolumn_activebtn-grn" : "invite_firstcolumn_activebtn-gry"}>{data.active}</button>
                        </div>
                    </ButtonComponent>
                </div>

            ))}
        </div>
    );
};
