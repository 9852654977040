import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import king from '../../../../../assets/img/king.png';
import { BlueTik } from '../../../../../common/all-icons';
import { SelectArrowDropdown } from '../../../../../common/selection-dropdown';
import { ButtonComponent } from '../../logo-header/view';
const view = () => {


  const options = [
    { value: 'Spotify', label: 'Spotify' },
    { value: 'Apple Music', label: 'Apple Music' },
    { value: 'Deezer', label: 'Deezer' },
    { value: 'Tidal', label: 'Tidal' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Youtube', label: 'Youtube' },
  ]
  return (
    <div
      className="modal fade"
      id="InviteModal8"
      aria-hidden="true"
      aria-labelledby="InviteModal8"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content modal-width-inviteModal">
          <div className="modal-header p-0">
            <h1 className="modal-title">Invitation</h1>
          </div>
 
          <div className="modal-body p-0">
            <TextfieldLG className=" uni-mt-16 ctm-txt">Please use the search to find the user you want to invite</TextfieldLG>
            <TextfieldSM className='uni-mb-56 modal-8-subcontent'>You may search for his display name or by his username</TextfieldSM>
           <DropworksSearchInput placeholder='Mandel' className='uni-mb-32'/>
          
            <div className='content-height uni-p-16'>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Mandelorian <BlueTik/></span> <TextfieldXSM>@munchybites</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Mandy</span> <TextfieldXSM>@mandelanater</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>MannyMan</span> <TextfieldXSM>@notmandela</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Melson Nandela</span> <TextfieldXSM>@Mandela12</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Mandelorian</span> <TextfieldXSM>@munchybites</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Mandelorian </span> <TextfieldXSM>@munchybites</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Mandelorian </span> <TextfieldXSM>@munchybites</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <p className='modal-8-box'></p>
                <TextfieldSM className='uni-mx-16 my-0 text-field'> <span className='d-block invite-8-head'>Mandelorian</span> <TextfieldXSM>@munchybites</TextfieldXSM></TextfieldSM>
                <DropworksRadioButton />
              </p>
            </div>
          </div>

          <div className="modal-footer p-0 uni-mt-32">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
                Cancel
            </DropworksSmallButton>
               <ButtonComponent id="InviteModal2">
            <DropworksSmallButton className="btn-wid m-0 my-0">Next</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default view;
 
 