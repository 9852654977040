import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import { DropworksSelect } from '../../../../../common/custom-field/dropworks-select';
import { SelectArrowDropdown } from '../../../../../common/selection-dropdown';

const view = () => {

  const options = [
    { value: "Spotify", label: "Spotify" },
    { value: "Apple Music", label: "Apple Music" },
    { value: "Deezer", label: "Deezer" },
    { value: "Tidal", label: "Tidal" },
    { value: "Instagram", label: "Instagram" },
    { value: "Youtube", label: "Youtube" },
  ];
  return (
    <div
    className="modal fade"
    id="SecurityQuestion2"
    aria-hidden="true"
    aria-labelledby="SecurityQuestion2"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
        <div className="modal-header p-0">
          <h1 className="modal-title">Security Questions</h1>
        </div>
        
        <div className="modal-body p-0">
          <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">Secure your account further</TextfieldLG>
          <TextfieldXSM className='uni-mb-56 ds-ctm-txt'>Never share the answers with anyone</TextfieldXSM>

          <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue='Security Question'
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          <DropworksInput placeholder='Rabolho'></DropworksInput>
          <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue='Security Question'
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          <DropworksInput placeholder='Answer'></DropworksInput>
          <DropworksDisableButton aria-placeholder='Security Question' className="custom-select-dropdown uni-mb-16">
              <SelectArrowDropdown
                options={options}
                placeHolderValue='Security Question'
              ></SelectArrowDropdown>
            </DropworksDisableButton>
          <DropworksInput placeholder='Answer' className='uni-mb-56'></DropworksInput>
        </div>  
        <div className="modal-footer p-0">
          <DropworksSmallButton  className="btn-wid m-0">Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;
