import { FC, useState } from "react";
import view from "./view";
import { TabsInterface } from "./modal";

const Tabs: FC<TabsInterface> =({tabsTitle,className,checkTabState})=>{
    const [currentSection, setCurrentSection] = useState<number>(0);
    const changeState=(value:number)=>{
        setCurrentSection(value);
        checkTabState && checkTabState(value);
    }
    return(view({tabsTitle,currentSection,changeState,className}))
}
export default Tabs;
