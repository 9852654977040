import React, { FC } from "react";
import { view } from "./view";
import ArrowDown from "../../assets/steps/arrow-down.svg";

interface Option {
  value: string;
  label: string;
}

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: Option[];
  className?: string;
  placeHolderValue?:string;
  maxheight?:number;
}

export const SelectArrowDropdown: FC<DropdownProps> = ({ maxheight ,options, ...props }) => {
  return view({ options,...props});
  
};
