import React, { FC } from "react";
import "./styles.scss";
import meatballsIcon from "../../assets/img/icons/meatballs-menu-icon.svg"
import { ButtonComponent } from "../../app/shared/components/logo-header/view";

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  arr: string[];
  height?: string;
  icon?: any;
  className?: string;
}
export const view: FC<DropdownProps> = ({ arr, icon = meatballsIcon, className, height }) => {
  return (
    <div className='dropdown d-flex' onClick={(e: any) => e.stopPropagation()}>
      <div
        className={`meatballs-menu dropdown-toggle ${className}`}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >

        <img src={icon} className="meatballs-menu-image" style={{ height: `${height}` }} alt="img" />

      </div>
      <ul className="dropdown-menu dropdown-menu-meatballs-Icon "> 
        {arr.map((data) => (
          <li key={data}>
             <ButtonComponent id={data === "Report" ? "ReportModal1" : data === "Block"? "BlockedModal": data === "Change Alias"? "ChangeAlias":data === "Remove"? "RemoveCollaborator":data === "Delete"? "DeleteFile":data === "Approve"? "ApproveFile":data === "Decline"? "DeclineFile":""}>
            <a className="dropdown-item dropdown-item-meatballs" href="#">
              {data}
            </a>
            </ButtonComponent>
          </li>
        ))}
      </ul>
    </div>
  );
};

