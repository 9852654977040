import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import { DropworksButton, DropworksNormalButton, GradientActiveBtn } from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from "../../../common/custom-field/dropstock-text";
import { Heading0, Heading3, Heading4, Heading5 } from "../../../common/custom-field/dropstock-heading";
import Tabs from "../../../common/tabs";
import TabsComponent from "../../../common/tabs-component/view";
import { RiArrowDropDownLine } from "react-icons/ri";

import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { DropworksInputMarketplace } from "../../../common/custom-field/dropworks-input-marketplace";
import TabsMarketplace from "../../../common/tabs-marketPlace";


const view: FC<viewType> = ({ navigateTo }) => {

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value)
  }
  const tableData = [
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel"
    }
  ];


  const tableData2 = [
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Canceled"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Canceled"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    }, {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    }, {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    }, {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    }, {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    }, {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled"
    }
  ];


  const tableData4 = [
    {
      assest: "USD",
      price: "-",
      quantity: 1000,
      total: "1000 USD",
      avgCost: "-"
    },
    {
      assest: "USD",
      price: "-",
      quantity: 1000,
      total: "1000 USD",
      avgCost: "-"
    },
    {
      assest: "USD",
      price: "-",
      quantity: 1000,
      total: "1000 USD",
      avgCost: "-"
    }
  ];

  const [tabsValue2, setTabValue2] = useState(0);
  const checkTabState2 = (value: number) => {
    setTabValue2(value)
  }

  const [tabsValue3, setTabValue3] = useState(0);
  const checkTabState3 = (value: number) => {
    setTabValue3(value)
  }

  const priceData = [
    { price: 3.50, quantity: 1000, total: 4050 },
    { price: 3.40, quantity: 1000, total: 4020 },
    { price: 3.30, quantity: 1000, total: 3990 },
    { price: 3.20, quantity: 1000, total: 3320 },
    // Add more data here...
  ];

  const chartData = {
    labels: ['08/14', '08/15', '08/16', '08/17', '08/18', '08/19', '08/20', '08/14', '08/15', '08/16', '08/17', '08/18', '08/19', '08/20', '08/14', '08/15', '08/16', '08/17', '08/18'],
    datasets: [
      {
        label: 'Price Over Time',
        data: [1, 4, 1, 4, 6, 8, 6, 6, 10, 11, 8, 6, 6, 10, 8, 6, 6, 10, 8, 6, 6, 6, 8, 10, 12],
        fill: false,
        backgroundColor: 'yellow',
        borderColor: 'yellow',
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const chartData2 = {
    labels: ['08/14', '08/15', '08/16', '08/17', '08/18', '08/19', '08/20', '08/14', '08/15', '08/16', '08/17', '08/18', '08/19', '08/20', '08/14', '08/15', '08/16', '08/17', '08/18'],
    datasets: [
      {
        label: 'Price Over Time',
        data: [1, 2, 5, 8, 10, 9, 12, 13, 15, 17, 14, 19, 18, 17, 19, 20, 23, 26, 30],
        fill: false,
        backgroundColor: 'yellow',
        borderColor: 'yellow',
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const chartData3 = {
    labels: ['08/14', '08/15', '08/16', '08/17', '08/18', '08/19', '08/20', '08/14', '08/15', '08/16', '08/17', '08/18', '08/19', '08/20', '08/14', '08/15', '08/16', '08/17', '08/18'],
    datasets: [
      {
        label: 'Price Over Time',
        data: [3.15, 3.20, 3.15, 3.20, 3.30, 3.30, 3.40, 3.30, 3.30, 3.50, 3.30, 3.30, 3.35, 3.30, 3.40, 3.30, 3.45, 3.50, 3.60],
        fill: false,
        backgroundColor: 'yellow',
        borderColor: 'yellow',
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      y: {
        display: false, // Hide y-axis and its labels
      },
      x: {
        display: false, // Hide x-axis and its labels
      },
    },
    elements: {
      point: {
        radius: 0, // Hides the data points (dots)
      },
    },
  };

  return (
    <div className="container marketplace-dropworksSelect-main">
      <div className="d-flex justify-content-between marketplace-dropworksSelect-main-head">
        <div className="marketplace-dropworksSelect-main-head-first">
          <Heading4 className="mb-0">DropWorks Composite <RiArrowDropDownLine /></Heading4>
          <TextfieldSM className="marketplace-dropworksSelect-main-head-first-subheading mx-0 my-0">DWS</TextfieldSM>
          <TextfieldLG className="marketplace-dropworksSelect-main-head-first-text3 mb-0">Portfolio Tracks Information</TextfieldLG>
        </div>
        <div className="d-flex gap-4 text-end marketplace-dropworksSelect-main-head-second">
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4">Starting at</TextfieldLG>
            <Heading5>$3.22</Heading5>
          </div>
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4">YTD returns</TextfieldLG>
            <Heading5>2.52%</Heading5>
          </div>
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4">Tracks</TextfieldLG>
            <Heading5>238</Heading5>
          </div>
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4">Issued shares</TextfieldLG>
            <Heading5>50K</Heading5>
          </div>
        </div>
      </div>



      <div className="row  marketplace-dropworksSelect-main-col1">
        <div className="col-3 table-main uni-p-16 ">
          <table className="marketplace-dropworksSelect-main-col1-table">
            <thead>
              <tr>
                <th className="">Price</th>
                <th >Quantity</th>
                <th className="text-end">Total</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(11)].map((_, index) => (
                <tr key={index}>
                  <td className="colour-red">$4.22</td>
                  <td >1000</td>
                  <td className="text-end">4500.00</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex align-items-center uni-mt-8 uni-mb-8">
            <TextfieldLG className="mx-0 my-0">$3.22</TextfieldLG>
            <TextfieldSM className="mx-0 my-0">Current price</TextfieldSM>
          </div>
          <table className="marketplace-dropworksSelect-main-col1-table">
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index}>
                  <td className="colour-green">$4.22</td>
                  <td>1000</td>
                  <td className="text-end">4500.00</td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>
        <div className="col-9 second-col px-0">
          <div className="chart-main">
            <div className="d-flex align-items-center">
              <div>
                <TextfieldXSM className="uni-mb-40">$3.50</TextfieldXSM>
                <TextfieldXSM className="uni-mb-40">$3.50</TextfieldXSM>
                <TextfieldXSM className="uni-mb-40">$3.50</TextfieldXSM>
                <TextfieldXSM>$3.50</TextfieldXSM>
              </div>
              <div className="w-100">
                <div className="d-flex uni-mb-8">
                  <TextfieldXSM className=" my-0 text-nowrap uni-mr-24 colour-g3">Time scale</TextfieldXSM>
                  <TabsMarketplace tabsTitle={["Week", "Month", "Year"]} checkTabState={checkTabState3} />

                </div>
                <TabsComponent tabsValue={tabsValue3} components={[
                  <>  <Line data={chartData} options={chartOptions} /></>,
                  <><Line data={chartData2} options={chartOptions} /></>,
                  <Line data={chartData3} options={chartOptions} />
                ]} />

              </div>

            </div>
            <div className="d-flex">
              <TextfieldXSM>08/15</TextfieldXSM>
              <TextfieldXSM>08/16</TextfieldXSM>
              <TextfieldXSM>08/17</TextfieldXSM>
              <TextfieldXSM>08/18</TextfieldXSM>
              <TextfieldXSM>08/19</TextfieldXSM>
              <TextfieldXSM>08/20</TextfieldXSM>

            </div>

          </div>



          <Tabs tabsTitle={["Limit", "Market"]} checkTabState={checkTabState2} className="uni-mb-16 uni-ml-32" />
          <TabsComponent tabsValue={tabsValue2} components={[
            <>
              <div className="row">
                <div className="col-6 uni-p-32 ">
                  {/* <DropworksInput placeholder="Price" /> */}
                  <DropworksInputMarketplace placeholder="Price" unit="USD" className="uni-mb-8" />
                  <DropworksInputMarketplace placeholder="Quantity" unit="DWS" />
                  <DropworksInputMarketplace placeholder="Total" unit="USD"/>
                  <div className="d-flex justify-content-between uni-mb-16">
                    <TextfieldXSM className="mx-0 my-0 text-available">Available</TextfieldXSM>
                    <TextfieldXSM className="mx-0 my-0">1000 USD</TextfieldXSM>
                  </div>
                  <DropworksNormalButton className="btn-green uni-mt-16">Buy</DropworksNormalButton>
                </div>
                
                <div className="col-6 uni-p-32">
                  <DropworksInputMarketplace placeholder="Price" unit="USD"/>
                  <DropworksInputMarketplace placeholder="Quantity" unit="DWS" />
                  <DropworksInputMarketplace placeholder="Total" unit="USD" />
                  <div className="d-flex justify-content-between uni-mb-16">
                    <TextfieldXSM className="mx-0 my-0 text-available">Available</TextfieldXSM>
                    <TextfieldXSM className="mx-0 my-0">1000 USD</TextfieldXSM>
                  </div>
                  <DropworksNormalButton className="btn-red uni-mt-16">Sell</DropworksNormalButton>
                </div>
              </div>
            </>,
            <>
              <div className="row">
                <div className="col-6 uni-p-32">
                  <DropworksInputMarketplace placeholder="Price" unit="DWS" className="uni-mb-8" />
                  <DropworksInputMarketplace placeholder="Price" unit="USD"/>

                  <div className="d-flex justify-content-between uni-mb-16">
                    <TextfieldXSM className="mx-0 my-0 text-available">Available</TextfieldXSM>
                    <TextfieldXSM className="mx-0 my-0">1000 USD</TextfieldXSM>
                  </div>
                  <DropworksNormalButton className="btn-green uni-mt-16">Buy</DropworksNormalButton>
                </div>
                <div className="col-6 uni-p-32">
                  <DropworksInputMarketplace placeholder="Price" unit="DWS"/>
                  <DropworksInputMarketplace placeholder="Price" unit="USD"/>

                  <div className="d-flex justify-content-between uni-mb-16">
                    <TextfieldXSM className="mx-0 my-0 text-available">Available</TextfieldXSM>
                    <TextfieldXSM className="mx-0 my-0">1000 USD</TextfieldXSM>
                  </div>
                  <DropworksNormalButton className="btn-red uni-mt-16">Sell</DropworksNormalButton>
                </div>
              </div>
            </>
          ]} />

        </div>
      </div>



      <div className="marketplace-dropworksSelect-main-table-content">
        <Tabs tabsTitle={["Active Orders", "Order History", "Transaction History", "Assets"]} className="uni-mb-24" checkTabState={checkTabState} />

        <TabsComponent tabsValue={tabsValue} components={[
          <>
            <table className="d-table w-100 marketplace-dropworksSelect-table">
              <thead className="uni-pb-32">
                <tr>
                  <th scope="col">
                    Date <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Product <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Type <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Price <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Quantity <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Total <RiArrowDropDownLine />
                  </th>
                  <th scope="col">Filled</th>
                  <th scope="col" className="colour-yellow text-end">
                    Cancel all
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <span className="text-wh">{row.date}</span>{" "}
                      <span className="time">{row.time}</span>
                    </td>
                    <td className="text-wh">{row.product}</td>
                    <td className="colour-green">{row.type}</td>
                    <td>{row.price}</td>
                    <td>{row.quantity}</td>
                    <td>{row.total}</td>
                    <td>{row.filled}</td>
                    <td className="colour-yellow text-end">{row.action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>,
          <>
            <table className="d-table w-100 marketplace-dropworksSelect-table">
              <thead className="uni-pb-32">
                <tr>
                  <th scope="col">
                    Date <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Product <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Type <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Price <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Quantity <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Total <RiArrowDropDownLine />
                  </th>
                  <th scope="col">Filled</th>
                  <th scope="col" className=" text-end">
                    State
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData2.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <span className="text-wh">{row.date}</span>{" "}
                      <span className="time">{row.time}</span>
                    </td>
                    <td className="text-wh">{row.product}</td>
                    <td className="colour-green">{row.type}</td>
                    <td>{row.price}</td>
                    <td>{row.quantity}</td>
                    <td>{row.total}</td>
                    <td>{row.filled}</td>
                    <td className=" text-end">{row.action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>,
          <>
            <table className="d-table w-100 marketplace-dropworksSelect-table table-fixed">
              <thead className="uni-pb-32">
                <tr>
                  <th scope="col">
                    Date <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Product <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Type <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Price <RiArrowDropDownLine />
                  </th>
                  <th scope="col">
                    Quantity <RiArrowDropDownLine />
                  </th>
                  <th scope="col" className="text-end">
                    Total <RiArrowDropDownLine />
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <span className="text-wh">{row.date}</span>{" "}
                      <span className="time">{row.time}</span>
                    </td>
                    <td className="text-wh">{row.product}</td>
                    <td className="colour-green">{row.type}</td>
                    <td>{row.price}</td>
                    <td>{row.quantity}</td>
                    <td className="text-end">{row.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>,
          <>
            <table className="d-table w-100 marketplace-dropworksSelect-table table-fixed">
              <thead className="uni-pb-32">
                <tr>
                  <th scope="col">
                    Asset
                  </th>
                  <th scope="col">
                    Price
                  </th>
                  <th scope="col">
                    Quantity
                  </th>
                  <th scope="col">
                    Total
                  </th>
                  <th scope="col " className="text-end">Avg. Cost</th>
                </tr>
              </thead>
              <tbody>
                {tableData4.map((row, index) => (
                  <tr key={index}>
                    <td className="text-wh">
                      {row.assest}
                    </td>
                    <td >{row.price}</td>
                    <td >{row.quantity}</td>
                    <td>{row.total}</td>
                    <td className="text-end">{row.avgCost}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>,
        ]} />
      </div>
    </div>
  );



};

export default view;
