import { FC } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../../common/custom-field/dropworks-textArea";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
const view: FC<viewType> = ({ navigateTo }) => {
  return (
    <div className="settingUp row align-items-center container pb-3 m-auto h-100  justify-content-between px-0">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
          <div className="settingUp_heading fs-2 mb-2">
            Setting up your profile
          </div>
          <div className="signUp_normal uni-my-32  custom-text-sm">
            Please fill out all the fields
          </div>
          <form>
            <DropworksInputIcon placeholder="Username" />
            <DropworksInputIcon placeholder="Display Name" />
            <DropworksTextArea placeholder="Biography (optional)" rows={4} />
          </form>
          <ButtonComponent id="Modal1">
          <DropworksButton>Finish</DropworksButton>
          </ButtonComponent>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mt-5 mt-sm-0">
          <div className="settingUp_uploadbox flex-column mb-3 minh-470">
              <img src={iconImg} />
              <label className="mt-4"> Profile Picture</label>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <DropworksButton className="settingUp_register_finish">
              Randomize
            </DropworksButton>
          </div>
        </div>
    </div>
  );
};
export default view;
