import React, { FC } from "react";
import "./styles.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const view: FC<InputProps> = ({ className = "", ...rest }) => {
  if(rest.type==="checkbox")
  {
    return <input className={`custom_inp_checkbox ${className}`} {...rest} />
  }
  else{
    return <input className={`custom_inp ${className}`} {...rest} />;
  }
};
