import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="UploadBanner2"
      aria-hidden="true"
      aria-labelledby="UploadBanner2"
      tabIndex={-1}
      data-bs-theme="dark"
    >             
      <div className="modal-dialog   mx-auto   modal-dialog-centered custom-modal-width ">
     
        <div className="modal-content modal-width-upload-banner">
          <div className="modal-header p-0">
            <h1 className="modal-title">Upload a Banner</h1>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-16">Try to use a 16:9 high resolution banner</TextfieldLG>
            <TextfieldXSM className="ctm-txt2 uni-mb-56">(Don’t upload anything if you do not want this do be displayed on your profile)</TextfieldXSM>

            <ButtonComponent id="ProfilePictureModal2">
            <div className="uploadBanner_uploadbox mx-0 my-0">
              <img src={iconImg} className='uploadBanner-uploadicon' />
              <TextfieldSM className='text-content uploadBanner-upload-text mx-0 my-0'>Upload</TextfieldSM>
            </div>
            </ButtonComponent>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid m-0">Save</DropworksSmallButton>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default view;

