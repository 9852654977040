import React, { FC, useState } from "react";
import "./styles.scss";
import { GoEye, GoEyeClosed } from "react-icons/go";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showHide:()=>void,
  show:boolean
}

export const view: FC<InputProps> = ({ className = "" ,show ,showHide,placeholder='Password',...rest}) => {
  return <div className="custom_pass_container"><input className={`custom_pass ${className}`} {...rest} type={show?"password":"text"} placeholder={placeholder}/><span  className="eye-icon text-light" >{!show?<GoEye className="text-light" onClick={()=>showHide()}/>:<GoEyeClosed  onClick={()=>showHide()}/>}</span></div>

};
