import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";

const view = () => {
  return (
    <div
    className="modal fade"
    id="acc-secured"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
        <div className="modal-header p-0">
          <h1 className="modal-title">Account Secured!</h1>
        </div>
        
        <div className="modal-body p-0">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">You can further increase your accounts security in the settings</TextfieldLG>
        </div>  
        <div className="modal-footer p-0">
          <DropworksSmallButton  className="btn-wid m-0">Finish</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


export const openButton=()=>{
  console.log("ppppppppppppp")
  document.getElementById('okk')?.click();
}