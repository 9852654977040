import React, { FC, useState } from "react";
import "./styles.scss";
import { GoEye, GoEyeClosed } from "react-icons/go";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  unit?: string;
}

export const view: FC<InputProps> = ({
  className = "",
  label = "Price",
  unit = "USD",
  placeholder = "Enter price",
  ...rest
}) => {
  return (
    <div className="custom_pass_container">

        <input
          className={`custom_pass ${className}`}
          {...rest}
          placeholder={placeholder}
        />
        <span className="eye-icon input-unit">{unit}</span>
        
    </div>
  );
};
