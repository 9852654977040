import React, { FC } from "react";
import "./styles.scss";
import { TabsInterface2 } from "./modal";

const View: FC<TabsInterface2> = ({ tabsTitle, currentSection, changeState, className = "" }) => {
  return (
    <div className={`d-flex flex-wrap tabs w-100 ${className}`}>
      <div className="tabs-container">
        {tabsTitle.map((item, index) => (
          <p
            key={index}
            className={`invite-button-all uni-mr-8 ${currentSection === index ? "active-invite" : ""}`}
            onClick={() => changeState && changeState(index)}
          >
            {item}
          </p>
        ))}
      </div>
    </div>
  );
};

export default View;
