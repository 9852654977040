import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
const view = () => {
  return (
    <div
      className="modal fade"
      id="SelectSubmit"
      aria-hidden="true"
      aria-labelledby="SelectSubmit"
      tabIndex={-1}
      data-bs-theme="dark"
    >
     <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content">
          <div className="modal-header p-0">
            <Heading3 className='Dropworks-select-submit-title'>DropWorks Select <br /> Track Submission</Heading3>
          </div>
          <div className="modal-body uni-mb-56 p-0"> 
          <TextfieldLG className='uni-mb-56 Dropworks-select-submit-subtitle'>Select the Drop you want to submit to DropWorks Select</TextfieldLG>
            <div className='content-height'>

              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select '>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>Under review</span> 
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div> 
              <div className='d-flex uni-p-16 content justify-content-between align-items-center'>
                <div className='d-flex gap-3 align-items-center'>
                  <div className='select-box'>
                  </div>
                  <div>
                <TextfieldLG  className='my-0  p-0 hot-summer-select'>Hot Summer</TextfieldLG>
                <TextfieldXSM className='my-0 dropworks-select-subtitle p-0'>Unlost, Uncovered, Meyna, Maluma</TextfieldXSM>
                </div>
                </div>
                <div className='d-flex gap-3'>
               <span className='owned-text'>(Owned: 800)</span> <DropworksRadioButton />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer p-0">
            <ButtonComponent id="DropStocksSelectsubmit3">
            <DropworksDisableButton className="btn-wid m-0  search-invite-upload custom-btn-upload">
              Next
            </DropworksDisableButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default view;
 
 