import "./styles.scss";
import { FC, useEffect, useState, useRef } from "react";
import Plyr from "plyr"; // Ensure you have installed plyr library
import "plyr/dist/plyr.css"; // Import Plyr's CSS
import { CrossCircle, CrossIcon, HeartIcon, LeftNextBtn, PlayerPauseBtn, RightNextBtn, VolumeUpIcon } from "../../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../../common/icons";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";

// Define Track type
interface Track {
  track: number;
  name: string;
  duration: string;
  file: string;
}

// Track list
const tracks: Track[] = [
  {
    track: 1,
    name: "All This Is - Joe L.'s Studio",
    duration: "2:46",
    file: "JLS_ATI",
  },
  // Add all other tracks here
  //...
];

const mediaPath = "https://archive.org/download/mythium/";
const extension = ".mp3"; // Adjust if needed
export const view: FC = () => {
  const [playing, setPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [npAction, setNpAction] = useState("Paused...");
  const audioRef = useRef<HTMLAudioElement>(null);
  const plyrRef = useRef<Plyr | null>(null);
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(["Report"]);

  useEffect(() => {
    if (audioRef.current) {
      plyrRef.current = new Plyr(audioRef.current, {
        controls: [
          "restart",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "download",
        ],
      });

      const audioElement = audioRef.current;

      audioElement.addEventListener("play", () => {
        setPlaying(true);
        setNpAction("Now Playing...");
      });

      audioElement.addEventListener("pause", () => {
        setPlaying(false);
        setNpAction("Paused...");
      });

      audioElement.addEventListener("ended", () => {
        setNpAction("Paused...");
        if (currentTrackIndex + 1 < tracks.length) {
          setCurrentTrackIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            loadTrack(newIndex);
            audioElement.play();
            return newIndex;
          });
        } else {
          setCurrentTrackIndex(0);
          loadTrack(0);
          audioElement.pause();
        }
      });
    }
  }, [currentTrackIndex]);

  const loadTrack = (index: number) => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      const track = tracks[index];
      audioElement.src = `${mediaPath}${track.file}${extension}`;
      setNpAction("Now Playing...");
      // Update the download link
      plyrRef.current?.on("loadedmetadata", () => {
        const downloadLink = document.querySelector('a[data-plyr="download"]');
        if (downloadLink) {
          (downloadLink as HTMLAnchorElement).href = audioElement.src;
        }
      });
    }
  };

  const handlePrev = () => {
    if (currentTrackIndex > 0) {
      setCurrentTrackIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        loadTrack(newIndex);
        if (playing) audioRef.current?.play();
        return newIndex;
      });
    } else {
      setCurrentTrackIndex(0);
      loadTrack(0);
    }
  };

  const handleNext = () => {
    if (currentTrackIndex + 1 < tracks.length) {
      setCurrentTrackIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        loadTrack(newIndex);
        if (playing) audioRef.current?.play();
        return newIndex;
      });
    } else {
      setCurrentTrackIndex(0);
      loadTrack(0);
    }
  };

  return (
      <div className="palyer container-fluid">
        <div className="palyer-nowPlay col-4">
          <div className="d-flex align-items-center">
            <div>
              <img
                src={trackImage}
                className="palyer-song-image"
                alt="user-image"
              />
            </div>
            <div className="player-content">
              <div className="w-100">
                <div className="player-details">
                  <h6 className="player-name">Hot Summer</h6>
                </div>
                <div className="player-song-title">
                  King Roland, Mabapupi Ocachino,Kahino, Lala Baby
                </div>
              </div>

              <div className="d-flex gap-3 track-page-bottons">
                <HeartIcon className="track-page-palyer"/>
                <MeatballsMenuDropdown
                  arr={MeatballsMenuOptions}
                  height={"6px"}
                ></MeatballsMenuDropdown>
              </div>
            </div>
          </div>

          {/* <span id="npAction">{npAction}</span>
          <span id="npTitle">{tracks[currentTrackIndex]?.name || ""}</span> */}
        </div>
        <div className="col-4">
          <div className="d-flex justify-content-center align-items-baseline gap-2">
            <div className="cursor-pointer"><LeftNextBtn/></div>
            <div className="cursor-pointer"><PlayerPauseBtn/></div>
            <div className="cursor-pointer"><RightNextBtn/></div>
          </div>
          <div className="player-controls gap-3">
            <div>2:46</div>
            <div className="progress cursor-pointer" style={{ height: "4px",width:"100%"}}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: "75%" }}
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              ></div>
            </div>
            <div>3:16</div>
          </div>
        </div>
        <div className="col-4 d-flex justify-content-end h-100">
          <div className="d-flex  align-items-center gap-3">
            <div className="cursor-pointer">

            <VolumeUpIcon/>
            </div>
            <div className="progress progress-volume cursor-pointer" style={{height: "4px",width:"150px"}}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: "75%" }}
                // aria-valuenow="25"
                // aria-valuemin="0"
                // aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div><CrossIcon className={"player-crossIcon"}/></div>
        </div>
      </div>
  );
};
