import { FC, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
}

export const DropworksSearchInput:FC<InputProps>=({...props})=>{
    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };
    const clearInput = () => {
      setInputValue("");
    };
    return view({handleInputChange,inputValue,clearInput,...props});
}