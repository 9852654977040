import { FC } from "react";
import "./styles.scss";

export const view: FC<any> = ({
    navigateTo,
    image,
    arr
}) => {
    return (
        <div className="row">
             {arr.map((data:any, index:any) => (
                <div className="col-12 col-sm-6 col-md-3 col-lg-2 project-icon" onClick={navigateTo("/project-page")}>
                    <div className="project-icon-box">
                    </div>  
                    <div className="project-icon-heading">{data.heading}</div>
                    <div className="project-icon-subheading">{data.subheading}</div>
                </div>
            ))}
        </div>
    );
};
