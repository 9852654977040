import { FC, useState } from "react";
import { view } from "./view";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  message?:(value:string)=>void
}

export const DropworksMessageInput:FC<InputProps>=({...props})=>{
    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);

    };
    
    const clearInput = () => {
      setInputValue("");
    };

    const sendMessage = () => {
      props.message && props.message(inputValue);
      setInputValue("");
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        props.message && props.message(inputValue)
        e.preventDefault(); // Prevent the default action (e.g., form submission)
        setInputValue("");
      }
    };

    return view({handleInputChange,handleKeyDown,inputValue,clearInput,sendMessage,...props});
}