import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainRoutes from './app/routing/MainRoutes';
import "../src/styles/variablesstyles.scss"
function App() {
  return (
    <BrowserRouter>
    <MainRoutes/>
    </BrowserRouter>
  );
}

export default App;
