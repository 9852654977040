import React, { FC } from "react";
import "./styles.scss";
import playmusic from "../assets/img/play-music-icon.png";
import playmusic2 from "../../assets/img/play-music-icon-2.png";
export const view: FC<any> = ({ navigateTo, image, arr }) => {
  return (
    <div className="row">
        {arr.map((data: any, index: any) => (
      <div className="col-12 col-sm-6 col-md-3 col-lg-2 track-icon">
            <div className="track-icon-box" onClick={navigateTo("/track-page")}>
              <img className="track-icon-box-icon" src={playmusic2} />
            </div>
            <div className="track-icon-heading">{data.heading}</div>
            <div className="track-icon-subheading">{data.subheading}</div>
          
      </div>

        ))}
    </div>
  );
};
