import React, { useEffect } from 'react'
import './guest-pages/home/styles.scss'
import gsap from "gsap";
import * as THREE from "three";
const Background = () => {

     useEffect(() => {
    createSparkleEffect(); // Sparkle effect

    const smoke = new Smoke();
     // Initialize smoke effect

    return () => {
      window.removeEventListener("resize", smoke.onResize);
    };
  }, []);

  function createSparkleEffect() {
    const container = document.getElementById(
      "effect-container"
    ) as HTMLElement;
    for (let i = 0; i < 100; i++) {
      const sparkle = document.createElement("div");
      sparkle.className = "sparkle";
      const size = Math.random() * 8 + 2;
      sparkle.style.width = `${size}px`;
      sparkle.style.height = `${size}px`;
      sparkle.style.left = `${Math.random() * window.innerWidth}px`;
      sparkle.style.top = `${Math.random() * window.innerHeight}px`;
      container.appendChild(sparkle);

      gsap.to(sparkle, {
        duration: Math.random() * 4 + 3,
        y: -window.innerHeight - 85, // move upwards off the screen
        opacity: 0,
        scaleX: 0,
        scaleY: 0,
        repeat: -1,
        ease: "power1.inOut",
      });
    }
  }

  // Smoke class for the Three.js smoke effect
  class Smoke {
    width: number;
    height: number;
    clock: THREE.Clock;
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera!: THREE.PerspectiveCamera;
    smokeParticles: THREE.Mesh[] = [];

    constructor() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.clock = new THREE.Clock();
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(this.width, this.height);
      document
        .getElementById("effect-container")
        ?.appendChild(this.renderer.domElement);

      this.scene = new THREE.Scene();
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load("homeImage_background.png", (texture:any) => {
        this.scene.background = texture;
      });

      this.addCamera();
      this.addLights();
      this.addParticles();

      this.update();

      this.addEventListeners();
    }

    evolveSmoke(delta: number) {
      const oscillationSpeed = 0.5; // Speed of oscillation
      const amplitude = 50; // Amplitude of oscillation

      this.smokeParticles.forEach((particle) => {
        particle.rotation.z += delta * 0.2;

        // Horizontal oscillation
        particle.position.x +=
          Math.sin(Date.now() * 0.001 * oscillationSpeed) * amplitude * delta;
        // Keep particles within the viewport bounds
        if (particle.position.x > window.innerWidth / 2) {
          particle.position.x = window.innerWidth / 2;
        } else if (particle.position.x < -window.innerWidth / 2) {
          particle.position.x = -window.innerWidth / 2;
        }
      });
    }

    addCamera() {
      this.camera = new THREE.PerspectiveCamera(
        78,
        this.width / this.height,
        1,
        650
      );
      this.camera.position.z = 50;
      this.scene.add(this.camera);
    }

    addLights() {
      const light = new THREE.DirectionalLight(0xffffff, 2);
      light.position.set(-1, 0, 1);
      this.scene.add(light);
    }

    addParticles() {
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(
        "https://rawgit.com/marcobiedermann/playground/master/three.js/smoke-particles/dist/assets/images/clouds.png",
        (texture:any) => {
          const smokeMaterial = new THREE.MeshLambertMaterial({
            color: 0xffffff,
            map: texture,
            transparent: true,
          });
          if (smokeMaterial.map) {
            smokeMaterial.map.minFilter = THREE.LinearFilter;
          }
          const smokeGeometry = new THREE.PlaneGeometry(250, 250);

          for (let i = 0; i < 100; i++) {
            const smokeMesh = new THREE.Mesh(smokeGeometry, smokeMaterial);
            smokeMesh.position.set(
              Math.random() * 500 - 250,
              Math.random() * 500 - 250,
              Math.random() * 1000 - 100
            );
            smokeMesh.rotation.z = Math.random() * 360;
            this.smokeParticles.push(smokeMesh);
            this.scene.add(smokeMesh);
          }
        }
      );
    }

    render() {
      this.renderer.render(this.scene, this.camera);
    }

    update() {
      const delta = this.clock.getDelta();
      this.evolveSmoke(delta);
      this.render();
      requestAnimationFrame(this.update.bind(this));
    }

    onResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    addEventListeners() {
      window.addEventListener("resize", this.onResize.bind(this));
    }
  }

  return (
    <div className='home'> <div className="row no-gutters">
    <div className="col-12">
        <img src="homeImgGirl.png" className="custom-img img-fluid" alt="Custom Image" />
        <div id="effect-container"></div>
    </div>
</div></div>
  )
}

export default Background;