import React from 'react'
import ProjectPageImg from "../../assets/img/project-page-img.png"
import BeatpageImg from "../../assets/img/beat-page-img.png"
import TrackPageImg from "../../assets/img/track-page-img.png"
import "./styles.scss"
const view = () => {
  return (
    <div className="image-grid-wrapper">
    <div className="scroll">
      <div>
      <img src={ProjectPageImg} />
      <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
        <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
      </div>
      <div>
      <img src={ProjectPageImg} />
      <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
        <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />

      </div>
    </div>
    <div className="scroll">
    <div>
      <img src={TrackPageImg} />
      <img src={ProjectPageImg} />
      <img src={TrackPageImg} />
      <img src={BeatpageImg} />
      <img src={ProjectPageImg} />
      <img src={TrackPageImg} />
      <img src={BeatpageImg} />
      </div>
      <div>
      <img src={TrackPageImg} />
      <img src={ProjectPageImg} />
      <img src={TrackPageImg} />
      <img src={BeatpageImg} />
      <img src={ProjectPageImg} />
      <img src={TrackPageImg} />
      <img src={BeatpageImg} />
      </div>
    </div>{" "}
    <div className="scroll">
      <div>
      <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
        <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
        <img src={BeatpageImg} />
      </div>
      <div>
      <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
        <img src={BeatpageImg} />
        <img src={TrackPageImg} />
        <img src={ProjectPageImg} />
        <img src={BeatpageImg} />
      </div>
    </div>
  </div>
  )
}

export default view