import { FC } from "react"
import { viewType } from "./modal"
import "./styles.scss";
import {FaXTwitter} from 'react-icons/fa6';
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { FcGoogle } from "react-icons/fc";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";

export const view :FC<viewType> = ({ navigateTo }) => {
  return (
    <div className="login page-content row align-items-center container pb-3 m-auto h-100 px-0">
    <div className="login_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
    <h1 className="login_heading my-0">Welcome to DropWorks</h1>
      <TextfieldSM className="login_normal uni-my-32">
        Use one of the options below to login
      </TextfieldSM>
      <form>
        <DropworksInput  placeholder="Email"/>
        <DropworksPassword className="uni-mb-32"  />
        <DropworksButton className="my-0 login_register">Login</DropworksButton>
        <TextfieldSM className="login_or uni-my-16 text-center">OR</TextfieldSM>
        <DropworksButton className="login_register_google uni-mb-32"> <FcGoogle className="login_icon" /> Sign in with Google</DropworksButton>
        <p className="login_already_registered uni-mb-24 fs-6">Don’t have an account?   <span className="login_logins"  onClick={navigateTo("/sign-up")}>Register</span></p>
        <p className="login_already_registered  fs-6">Forgot your password? <span className="login_logins"  onClick={navigateTo("/recover-account-email")}> Recover   </span></p>
      </form>
    </div>
  </div>
  )
}

