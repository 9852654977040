import { FC, useState } from "react";
import "./styles.scss";
import Tabs from "../../../../common/tabs";
import {
  DropworkGhostButton,
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TextfieldLG } from "../../../../common/custom-field/dropstock-text";
import Producer from "../../../../assets/img/Producer.png";
import verifiedIcon from "../../../../assets/steps/verified-badge.svg";
import downloadIcon from "../../../../assets/steps/download.svg";
import { MeatballsMenuDropdown } from "../../../../common/icons";
import TabsComponent from "../../../../common/tabs-component/view";
import { viewType } from "./modal";
import { ButtonComponent } from "../../../shared/components/logo-header/view";

export const view: FC<viewType> = ({ navigateTo }) => {
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Block",
    "Report",
  ]);
  return (
    <div className="new-wallet container container-xxl min-height-cover">
      <h1 className="new-wallet-heading uni-mb-40">Wallet</h1>
      <Tabs
        tabsTitle={["Overview", "Transactions", "Orders"]}
        className="uni-mb-24"
        checkTabState={checkTabState}
      />
      <TabsComponent tabsValue={tabsValue}
        components={[
        <div>
          <div className="overview">
            <div className="overview-total-balance uni-mt-40 uni-mb-40 ">
              <div>
                <TextfieldLG className="d-grid">
                  Total Balance
                  <div className="balance-amount d-flex">
                    $8000.22{" "}
                    <div className="d-flex uni-ml-56 withdraw-deposit gap-3">
                    <ButtonComponent id="WithdrawModal">
                      <DropworksDisableButton className="withdraw-button">
                        Withdraw
                      </DropworksDisableButton>
                      </ButtonComponent>
                      <ButtonComponent id="DepositModal">
                      <DropworksSmallButton className="deposit-button">
                        Deposit
                      </DropworksSmallButton>
                      </ButtonComponent>
                    </div>
                  </div>
                </TextfieldLG>
              </div>
            </div>
            <h5 className="liquid-asstes-head uni-mb-32">Liquid assets</h5>
            <div className="liquid-assets uni-mb-56">
              <div className="table-view">
                <div className="table-bg-parent">
                  <div className="table-wrapper-2">
                    <table className="table custom-table-3">
                      <thead className="text-dark overview-sec">
                        <tr>
                          <th scope="col " className="space-table-head">Asset</th>
                          <th scope="col" className="space-table-head">Quantity/ Value</th>
                          <th scope="col" className="space-table-head">Price/ Avg. Cost</th>
                          <th scope="col" className="space-table-head">Royalties</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-light">
                          <td className="date-time-2">
                            <div className="d-flex gap-3 align-items-center">
                              <span className="select-box"></span>
                              <div className="d-flex cursor-pointer" onClick={navigateTo("/marketplace-dropworks-composite")}>
                                <TextfieldLG className="my-0  p-0 select-title">
                                  DropWorks Composite
                                </TextfieldLG>
                              </div>
                            </div>
                          </td>
                          <td className="quantity">
                            <span className="table-data">123</span>
                            <span className="table-subdata">$332.26</span>
                          </td>
                          <td className="price">
                            <span>$3.56</span>
                            <span className="table-subdata"> $2.34</span>
                          </td>
                          <td>$ 39.56</td>
                        </tr>
                        <tr className="text-light">
                          <td className="date-time-2">
                            <div className="d-flex gap-3 align-items-center">
                              <span className="select-box"></span>
                              <div className="d-flex cursor-pointer" onClick={navigateTo("/marketplace-dropworks-select")}>
                                <TextfieldLG className="my-0  p-0 select-title">
                                  DropWorks Select
                                </TextfieldLG>
                              </div>
                            </div>
                          </td>
                          <td className="quantity">
                            <span>123</span>
                            <span className="table-subdata">$332.26</span>
                          </td>
                          <td className="price">
                            <span>$3.56</span>
                            <span className="table-subdata"> $2.34</span>
                          </td>
                          <td>$ 39.56</td>
                        </tr>
                        <tr className="text-light">
                          <td className="date-time-2">
                            <div className="d-flex gap-3 align-items-center">
                              <span className="select-box"></span>
                              <div className="d-flex">
                                <TextfieldLG className="my-0  p-0 select-title">
                                  USD
                                </TextfieldLG>
                              </div>
                            </div>
                          </td>
                          <td className="quantity">
                            <span>123</span>
                            <span className="table-subdata">$332.26</span>
                          </td>
                          <td className="price">
                            <span>-</span>
                          </td>
                          <td>-</td>
                        </tr>
                        <tr className="text-light">
                          <td className="date-time-2">
                            <div className="d-flex gap-3 align-items-center">
                              <span className="select-box"></span>
                              <div className="d-flex cursor-pointer" onClick={navigateTo("/marketplace-dropworks-composite")}>
                                <TextfieldLG className="my-0  p-0 select-title">
                                  DropWorks Composite
                                </TextfieldLG>
                              </div>
                            </div>
                          </td>
                          <td className="quantity">
                            <span>123</span>
                            <span className="table-subdata">$332.26</span>
                          </td>
                          <td className="price">
                            <span>$3.56</span>
                            <span className="table-subdata"> $2.34</span>
                          </td>
                          <td>$ 39.56</td>
                        </tr>
                        <tr className="text-light">
                          <td className="date-time-2">
                            <div className="d-flex gap-3 align-items-center">
                              <span className="select-box"></span>
                              <div className="d-flex cursor-pointer" onClick={navigateTo("/marketplace-dropworks-composite")}>
                                <TextfieldLG className="my-0  p-0 select-title">
                                  DropWorks Composite
                                </TextfieldLG>
                              </div>
                            </div>
                          </td>
                          <td className="quantity">
                            <span>123</span>
                            <span className="table-subdata">$332.26</span>
                          </td>
                          <td className="price">
                            <span>$3.56</span>
                            <span className="table-subdata"> $2.34</span>
                          </td>
                          <td>$ 39.56</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <h5 className="dropstocks-asstes-head uni-mb-32">DropStocks</h5>
            <div className="dropstocks-assets">
              <div className="table-view">
                <div className="table-bg-parent">
                  <div className="table-wrapper">
                    <table className="table custom-table-2">
                      <thead className="text-dark d-flex align-items-center justify-content-between ">
                        <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                          <th scope="col" className="space-table-head">Asset</th>
                          <th scope="col"  className="space-table-head">Quantity</th>
                        </tr>
                      </thead>
                      <tbody className="uni-mt-32">{[1,2,3,4,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,].map((ele)=>
                      {
                        return( 
                        <tr  className="text-light d-flex justify-content-between align-items-center">
                             <td className="date-time">
                            <div className="d-flex gap-3 align-items-center">
                              <span className="select-box"></span>
                              <div className="d-flex cursor-pointer" onClick={navigateTo("/track-page")}>
                                <TextfieldLG className="my-0  p-0 select-title">
                                  Track Name 1
                                </TextfieldLG>
                              </div>
                            </div>
                          </td>

                          <td>123</td>
                        </tr>
                        )})}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>,
        <div>
        <div className="d-flex gap-3 uni-mb-40 uni-mt-32">
          <DropworkGhostButton>Balance</DropworkGhostButton>
          <DropworkGhostButton>Royalties</DropworkGhostButton>
        </div>

        <div className="table-view">
          <div className="table-bg-parent">
            <div className="table-wrapper">
              <table className="table custom-table">
                <thead className="text-dark">
                  <tr>
                    <th scope="col">
                      Date <RiArrowDropDownLine />
                    </th>
                    <th scope="col">
                      Source <RiArrowDropDownLine />
                    </th>
                    <th scope="col">
                      Type <RiArrowDropDownLine />
                    </th>
                    <th scope="col">
                      Value <RiArrowDropDownLine />
                    </th>
                    <th scope="col">
                      Status <RiArrowDropDownLine />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>
                    <td>-</td>
                    <td>Deposit</td>
                    <td>$ 500.00</td>
                    <td>Processing</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td className="cursor-pointer" onClick={navigateTo("/track-page")}>Track Name</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                  <tr className="text-light">
                    <td className="date-time">
                      <span className="date">08/23/2024</span>{" "}
                      <span className="time">18:23:17</span>
                    </td>

                    <td>DWC</td>
                    <td>Royalty</td>
                    <td>$ 500.00</td>
                    <td>Completed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>, 
      <div>
          <div className="d-flex gap-3 uni-mb-40">
            <DropworkGhostButton>Active</DropworkGhostButton>
            <DropworkGhostButton>History</DropworkGhostButton>
          </div>

          <div className="table-view">
            <div className="table-bg-parent">
              <div className="table-wrapper">
                <table className="table custom-table">
                  <thead className="text-dark">
                    <tr>
                      <th scope="col">
                        Date <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Symbol <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Type <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Quantity/Value <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Status <RiArrowDropDownLine />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>
                      <td>-</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span className="table-subdata">$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Active</td>
                    </tr>
                    <tr className="text-light">
                      <td className="date-time">
                        <span className="date">08/23/2024</span>{" "}
                        <span className="time">18:23:17</span>
                      </td>

                      <td>DWC</td>
                      <td>Buy</td>
                      <td className="quantity">
                        <span>123</span> <span>$332.26</span>
                      </td>
                      <td>Completed</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>]}/>
  
     
    </div>
  );
};
