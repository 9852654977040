import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
      className="modal fade"
      id="SearchInvite"
      aria-hidden="true"
      aria-labelledby="SearchInvite"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content">
          <div className="modal-header p-0">
            <h1 className="modal-title">Invitation</h1>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh">Please use the search bar to find the user you want to invite</TextfieldLG>
            <TextfieldXSM className='uni-mb-56 search-input-display'>You may search for his display name or by his username</TextfieldXSM>
            <ButtonComponent id="InviteModal8">
            <DropworksSearchInput></DropworksSearchInput>
            </ButtonComponent>
          </div>
          <div className="modal-footer p-0 uni-mt-32">
            <DropworksDisableButton className="btn-wid search-invite-cancel my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksDisableButton>
            <DropworksDisableButton className="btn-wid m-0  search-invite-upload custom-btn-upload">
              Save
            </DropworksDisableButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

