import { FC } from "react";
import { view } from "./view";
import ArrowDown from "../../assets/steps/arrow-down.svg"
interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
    arr: string[];
    icon?:any;
    className?:string;
  height?:string;
}

export const ArrowDropdown:FC<DropdownProps>=({ arr,...props})=>{
    return view({ arr,icon:ArrowDown,...props});
}

export const MeatballsMenuDropdown:FC<DropdownProps>=({ arr,height,...props})=>{
    return view({ arr,height,...props});
}