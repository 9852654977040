import React, { FC } from "react";
import "./styles.scss";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

export const view: FC<SelectProps> = ({ className = "", ...rest }) => {
  return (
    <select className={`custom_select ${className}`} {...rest}>
      {rest.children}
    </select>
    // <select
    //   className={`custom_select ${" "} ${classNameValue ? classNameValue : ""}`}
    //   value={inputValue}
    //   onChange={onChangeFunc}
    // >
    //   <option selected disabled hidden>
    //     {placeholderValue || "Please Select"}
    //   </option>
    //   {!Options && <option disabled>No Options Available</option>}
    //   {Options && Options.length === 0 ? (
    //     <option disabled>No Options Available</option>
    //   ) : (
    //     Options?.map((ele, index) => (
    //       <option key={index} value={ele.value}>
    //         {ele.name}
    //       </option>
    //     ))
    //   )}
    // </select>
  );
};
