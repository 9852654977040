import { FC } from "react"
import { viewType } from "./modal"
import "./styles.scss";
import {FaXTwitter} from 'react-icons/fa6';
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { FcGoogle } from "react-icons/fc";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";

export const view :FC<viewType> = ({navigateTo}) => {
  return (
    <div className="recaccount page-content row align-items-center container pb-3 m-auto h-100 px-0">
    <div className="recaccount_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
    <h1 className="recaccount_heading my-0">Recover Account</h1>
      <TextfieldSM className="uni-my-32 recaccount_definepassword ">
      Please define your new password
      </TextfieldSM>
      <form>
        <DropworksPassword className="uni-mb-16"  placeholder="New Password"/>
        <DropworksPassword className="uni-mb-32" placeholder="Confirm Password" />
        <DropworksButton onClick={navigateTo("/login")}>Confirm</DropworksButton>
      </form>
    </div>
  </div>
  )
}

