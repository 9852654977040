import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"

const view = () => {
  return (
    <div
      className="modal fade"
      id="ReportModal4"
      aria-hidden="true"
      aria-labelledby="ReportModal4"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header p-0 uni-mb-40">
            <h2 className="modal-title-fs-32">What do you want to report?</h2>
          </div>

          <div className="modal-body p-0 uni-mb-56">
            <p className='d-flex justify-content-between uni-p-16 p-lh uni-mb-8'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">A specific track</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16 p-lh uni-mb-8'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">A specific beat</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16 p-lh uni-mb-8'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">Recent messages this user sent you</TextfieldSM>
              <DropworksRadioButton />
            </p>
            <p className='d-flex justify-content-between uni-p-16 p-lh'>

              <TextfieldSM className=" font-col-wh my-0 mx-0">Something about this user</TextfieldSM>
              <DropworksRadioButton />
            </p>


          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">Cancel</DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0">Next</DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


