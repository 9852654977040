import React from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { getLocalStorageObject } from "../shared/utils/localStorage";
import Home from "../content/guest-pages/home/Home";
import { Login } from "../content/guest-pages/login";
import { MessageChat } from "../content/messages-chat";
import LogoHeader from "../shared/components/logo-header";
import { Sign_Up } from "../content/guest-pages/sign-up";
import { Setting_Up_Page } from "../content/guest-pages/settingup-page";
import { Sign_Up_Fill_Details } from "../content/guest-pages/signup-filldetails";
import UnderMaintenance from "../content/under-maintenance";
import { Search } from "../content/search";
import { RecoverAccount } from "../content/guest-pages/recover-account";
import { LoginRecoverAccountEmail } from "../content/guest-pages/recover-account-email";
import { Invite } from "../content/invite";
import { Library } from "../content/studio/library";
import { Favorites } from "../content/studio/favorites";
import { MainNotification } from "../content/notification";
import { UserProfile } from "../content/user-profile";
import { DemoModal } from "../content/test-modal";
import { NewStudioheader } from "../shared/components/studio-header";
import { ProfilSetting } from "../content/guest-pages/profile-setting";
import { BeatesPage } from "../content/beat-page";
import { NewWallet } from "../content/studio/wallet";
import { BeatEmpty } from "../content/beat-empty";
import MarketplaceFeatured from "../content/featured-studio";
import { TrackPage } from "../content/track-page";
import { ProjectPage } from "../content/project-page";
import { TrackProjectOverview } from "../content/track-project-overview";
import Create from "../content/studio/create";
import { NewMarketplaceheader } from "../shared/components/marketplace-header";
import Background from "../content/Background";
import { MarketPlaceProducts } from "../content/marketplace-products";
import { MarketPlaceDropworksSelect } from "../content/marketplace-dropworks-select";
import { SelectPortfolio } from "../content/select-portfolio";
import { CompositePortfolio } from "../content/composite-portfolio";
import { PersonalPortfolio } from "../content/personal-portfolio";
import { MarketPlaceDropworkSelect } from "../content/marketplace-products-dw-select";
import { MarketPlaceDropworkComposite } from "../content/marketplace-products-dw-composite";
import { MarketPlaceDropworksSelectOption } from "../content/marketplace-dropworks-select-option";
import { MarketPlaceDropworksSelectComposite } from "../content/marketplace-drpworksSelect-composite";









const privateRoutesConfig: RouteObject[] = [
  {
    element: <div>main</div>,
    children: [
      {
        path: "/sign-up",
        element: <Sign_Up />,
      },
    ],
  },
  {
    path: "*",
    element: <Sign_Up />,
  },
];

const publicRoutesConfig: RouteObject[] = [
  {
    element: <LogoHeader />,
    children: [
      {
        path: "/test-modal",
        element: <DemoModal />,
      },
      {
        path: "/sign-up",
        element: <Sign_Up />,
      },
      {
        path: "/setting-up",
        element: <Setting_Up_Page />,
      },
      {
        path: "/fill-details",
        element: <Sign_Up_Fill_Details />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/recover-account",
        element: <RecoverAccount />,
      },
      {
        path: "/recover-account-email",
        element: <LoginRecoverAccountEmail />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path:"/",
        element:<Navigate to="/home" replace/>
      },  {
        path: "*",
        element:<Navigate to="/home" replace/>
      },
   
    ],
  },

  {
    
    element: <NewStudioheader />,
    children: [
      {
        path: "/marketplace-products",
        element: <MarketPlaceProducts/>,
      },
      {
        path: "/marketplace-dropworks-select",
        element: <MarketPlaceDropworkSelect/>,
      },
      {
        path: "/marketplace-dropworks-composite",
        element: <MarketPlaceDropworkComposite/>,
      },
      {
        path: "/dropworks-select",
        element: <MarketPlaceDropworksSelect/>,
      },
      {
        path: "/dropworks-select-composite",
        element: <MarketPlaceDropworksSelectComposite/>,
      },
      {
        path: "/dropworks-select-option",
        element: <MarketPlaceDropworksSelectOption
        />,
      },
   
     
    
      {
        path: "/message-chat",
        element: <MessageChat/>,
      },

     
      {
        path: "/user-profile",
        element: <UserProfile />,
      },
     
    
    
     
      {
        path: "/profile-setting",
        element: <ProfilSetting />,
      },
      {
        path: "notification",
        element: <MainNotification />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/favorites",
        element: <Favorites />,
      },
      {
        path: "/new-wallet",
        element: <NewWallet />,
      },
      {
        path: "/select-portfolio",
        element: <SelectPortfolio />,
      },
      {
        path: "/composite-portfolio",
        element: <CompositePortfolio/>
      },
      {
        path: "/personal-portfolio",
        element: <PersonalPortfolio/>
      }

     
     
    ],
  },
  {
    element: <NewMarketplaceheader />,
    children: [
      {
        path: "/track-project-overview",
        element: <TrackProjectOverview/>,
      },
      {
        path: "/beat-empty",
        element: <BeatEmpty />,
      },
      {
        path: "/create",
        element: <Create />,
      },
      {
        path: "/library",
        element: <Library />,
      },
      {
        path: "/featured",
        element: <MarketplaceFeatured />,
      },
      {
        path: "/invite",
        element: <Invite />,
      },
      {
        path: "/project-page",
        element: <ProjectPage/>,
      },
      {
        path: "/track-page",
        element: <TrackPage/>,
      },
      {
        path: "/beats-page",
        element: <BeatesPage />,
      },
     
      {
        path: "/message-chat2",
        element: <MessageChat/>,
      },        
    ]
    },
  {
    path: "/under-maintenance",
    element: <UnderMaintenance />,
  },
  {
    path: "/background",
    element: <Background />,
  },
];

const MainRoutes = () => {
  const token = getLocalStorageObject("userToken");
  const config = publicRoutesConfig; //isVerify disabled
  const routes = useRoutes(config);
  return routes;
};

export default MainRoutes;
