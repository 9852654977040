import { FC, useState } from "react";
import "./styles.scss";
import { DropworksSelect } from "../../../common/custom-field/dropworks-select";
import { DropworksButton } from "../../../common/custom-field/dropworks-button";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import { DropworksDropdown } from "../../../common/custom-field/dropworks-dropdown";
import kingRoland from '../../../assets/img/roland.png';
import { AiOutlineDown } from "react-icons/ai";
interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const view: FC<CustomDivProps> = ({...props}) => {

    return (
    
                <div className={`${props.className}  dropdown`}>
                    <div className="country-selector dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg" alt="Portugal Flag"/>
                            <span className="code">+351</span>
                            <AiOutlineDown className="dropdown-icon"/>
                    </div>
                    <ul className="dropdown-menu dropdown-ul" aria-labelledby="dropdownMenuButton">
                        <li className="dropdown-li">Albania</li>
                        <li className="dropdown-li">Algeria</li>
                        <li className="dropdown-li">Andorra</li>
                        <li className="dropdown-li">Angola</li>
                        <li className="dropdown-li">Anguilla</li>
                    </ul>
                </div>
    );
};
