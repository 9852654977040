import React, { FC } from "react";
import "./styles.scss";
import { RiQuestionFill } from "react-icons/ri";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const view: FC<TextareaProps> = ({ className = "", ...rest }) => {
  return (

    <div className="custom_inp_icon_container">
    <textarea
      className={`custom_inp_icon custom_textarea_inp ${className}`}
      {...rest}
    />
    <span className="question-icon">
        <RiQuestionFill  />
    </span>
  </div>
    // <div>
    // <textarea className={`custom_textarea_inp ${" "} ${className}`} {...rest} />
    // <span className="question-icon">
    //       <RiQuestionFill />
    //   </span>

    // </div>
  );
};
