import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
const view = () => {
  return (
    <div
      className="modal fade"
      id="InviteModal"
      aria-hidden="true"
      aria-labelledby="InviteModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content modal-width-inviteModal m-auto">
          <div className="modal-header p-0">
            <h1 className="modal-title">Invitation</h1>
          </div>
 
          <div className="modal-body p-0">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt">Select the project that you want to invite this user to</TextfieldLG>
 
            <div className='content-height'>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 my-0 text-field'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
              <p className='d-flex uni-p-16 content align-items-center'>
                <span className='box'></span>
                <TextfieldSM className='uni-mx-16 text-field my-0'>Project Name</TextfieldSM>
                <DropworksRadioButton />
              </p>
            </div>
          </div>
          <div className="modal-footer p-0 uni-mt-56">
          <ButtonComponent id="InviteModal2">
            <DropworksSmallButton className="btn-wid m-0 my-0">Next</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
 
export default view;
 
 