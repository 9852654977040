import React, { useEffect, useRef } from 'react'
import Scrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import { HeaderProps } from './modal';

const view: React.FC<HeaderProps> = ({ children }) => {

  const containerRef = useRef<HTMLDivElement>(null); 
  return (
      <div ref={containerRef} style={{ height: "100%", width: "100%", "overflowX":"hidden" }}>
        {children}
      </div>
  )
}

export default view;
