import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import "./styles.scss";
import { FC } from "react";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import king from '../../../../../assets/img/king.png';
import verfiedIcon from '../../../../../assets/steps/verified-badge.svg';
import { MdOutlineFileDownload } from "react-icons/md";
import download from '../../../../../assets/steps/download.svg';
interface viewType {
  navigateTo:(path:string)=>()=>void
} 

const view  :FC<viewType> =({ navigateTo }) => {
  return (
    <div
      className="modal fade"
      id="AcceptedInviteSent"
      aria-hidden="true"
      aria-labelledby="AcceptedInviteSent"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header p-0">
            <h1 className="modal-title">Invite <span className='invite-received-accepted'>Accepted</span> </h1>
          </div>
          <div className="modal-body p-0">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">You have been invited by @kingroland to participate in his project</TextfieldLG>
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <img src={king} className="invite_firstcolumn_imagebox" alt="" />
              <div onClick={navigateTo("/user-profile")} className='invite-modal-pointer'>
                <h2 className="fw-700 text-light">King Roland
                  <img src={verfiedIcon} alt="" className="uni-ml-8" /> </h2>
                  <TextfieldSM className='text-light'>@kingroland</TextfieldSM>
              </div>
             
            </div>

            <TextfieldSM className=' received-label uni-mb-16'>Project</TextfieldSM>
            <DropworksInput placeholder='Project Name' className='uni-mb-32 cstm-inp-invite'></DropworksInput>

            <TextfieldSM className='received-label uni-mb-16 '>Role</TextfieldSM>
            <DropworksInput placeholder='Vocalist' className='uni-mb-32 cstm-inp-invite'></DropworksInput>

            <TextfieldSM className=' received-label uni-mb-16 ' >Percentage Offered</TextfieldSM>
            <DropworksInput placeholder='15%' className='uni-mb-32 cstm-inp-invite'></DropworksInput>
            <TextfieldSM className='received-label uni-mb-16 '>Message</TextfieldSM>

            <DropworksTextArea className="uni-mb-32 cstm-txt-invite uni-p-16">
              Message example in this offer that is a very good example of a Message example in this offer that is a very good example of a Message example in this offer that is a very good example of a Message example in this offer that is a very good example of a Message example in this offer that is a
            </DropworksTextArea>
            <TextfieldLG className='text-light uni-mb-16'>Preview</TextfieldLG>
            <div className='d-flex justify-content-between uni-p-16 align-items-center uni-mb-32'>
               <TextfieldSM className='file-name'>File name .mp3</TextfieldSM>
               <TextfieldSM className='file-name'> <img src={download} alt="" className="uni-ml-8" /></TextfieldSM>
            </div>
            <div className=' uni-mb-32'>
              <TextfieldSM className='file-name uni-mb-8'>View <span className='collaboration'>Collaboration Agreement</span></TextfieldSM>
              <TextfieldSM className='received-date '>Received on mm/dd/yyyy and accepted on mm/dd/yyyy</TextfieldSM>
            </div>

          </div>
        
        </div>
      </div>
    </div>
  )
}

export default view;


