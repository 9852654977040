import React from "react";
import view from "./view";
interface CustomRadioButtonProps extends React.HTMLAttributes<HTMLSpanElement> {}

  export  const DropworksSwitch:React.FC<CustomRadioButtonProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} radiobutton`})
    );
  };

  
   



