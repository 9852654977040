import {
  TextfieldLG,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import hankeyman from '../../../../../assets/img/hankeyman.png';
const view = () => {
  return (
    <div
      className="modal fade"
      id="RemoveCollaborator"
      aria-hidden="true"
      aria-labelledby="RemoveCollaborator"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
          <div className="modal-header uni-mb-40 p-0">
            <h1 className="modal-title">Remove #ARTISTNAME? </h1>
          </div>
          <div className="modal-body p-0 uni-mb-56">
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <div><img src={hankeyman} alt="" /></div>
              <div>
                <h2 className="fw-700 displayname">
                King Roland   <BlueTik/>
                </h2>
              </div>
            </div>
            
             <TextfieldLG className="remove-content">You are about to remove #ARTISTNAME from this project.Continue?</TextfieldLG>
          </div>
          <div className="modal-footer p-0">
            <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksSmallButton>
            <DropworksSmallButton className="btn-wid m-0">
             Remove
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
