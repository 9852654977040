import React, { useState } from "react";
import "./styles.scss";
interface CustomRadioButtonProps
  extends React.HTMLAttributes<HTMLSpanElement> {}
const view: React.FC<CustomRadioButtonProps> = ({ ...props }) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

    const handleToggle = () => {
      setIsChecked(!isChecked);
    };
  return (
     <div>
      <label className="switch-main">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default view;
