import { FC } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksSelect } from "../../../../common/custom-field/dropworks-select";
import { TextfieldSM } from "../../../../common/custom-field/dropstock-text";

const view: FC<viewType> = ({ navigateTo }) => {
  return (
    <div className=" page-content row align-items-center container px-0 m-auto h-100">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-6 col-xxl-5">
        <h1 className="signUp_heading my-0">Welcome to DropWorks</h1>
        <TextfieldSM className="uni-my-32 signUp-fill-normal">
          Please fill out all the fields
        </TextfieldSM>
   
        <div className="row">
          <div className="col-6 pe-1 ps-0">
            <DropworksInput placeholder="Name" />
          </div>
          <div className="col-6 pe-0 ps-1">
            <DropworksInput placeholder="Surname" />
          </div>
        </div>
        <div className="row  mb-3 ">
          <div className="col-6 pe-1 ps-0">
            <DropworksSelect>
              <option disabled selected hidden>
                Birth Date
              </option>
              <option disabled>No Options Available</option>
            </DropworksSelect>
          </div>
          <div className="col-6 pe-0 ps-1">
            <DropworksSelect>
              <option disabled selected hidden>
                Gender
              </option>
              <option>Male</option>
              <option>Female</option>
              <option>Other</option>
            </DropworksSelect>
          </div>
        </div>
        <div className="row mb-3">
          <DropworksSelect>
            <option disabled selected hidden>
              Country
            </option>
            <option disabled>No Options Available</option>
          </DropworksSelect>
        </div>
        <DropworksButton onClick={navigateTo("/setting-up")}>Next</DropworksButton>
      </div>
    </div>
  );
};
export default view;
