import { FC, useState } from "react";
import "./styles.scss";
import { DropworksSearchInput } from "../../../../common/custom-field/dropworks-search-input";
import { DropworksDropdown } from "../../../../common/custom-field/dropworks-dropdown";
import { UserIcon } from "../../../../common/user-icon";
import { TrackIcon } from "../../../../common/track-icon";
import { ProjectIcon } from "../../../../common/project-icon";
import { BeatesIcon } from "../../../../common/beats-icon";
import searchIcon from "../../../../assets/img/searchIcon.svg";

export const view: FC = () => {
  const [userarr, setUserarr] = useState([
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
    { heading: "User Name", subheading: "@username" },
  ]);

  const [dropdownarrRoles, setDropdownarrRoles] = useState({
    heading: "Roles",
    option: [
      "Investor",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  });

  const [dropdownarrTracks, setDropdownarrTracks] = useState({
    heading: "Genre",
    option: ["Genre", "Genre", "Genre", "Genre", "Genre", "Genre", "Genre"],
  });
  const [trackarr, setTrackarr] = useState([
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Track Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [projectarr, setProjectarr] = useState([
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
    {
      heading: "Project Name",
      subheading: "Unlost, Uncovered, Meyna, Maluma and more",
    },
  ]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
    { heading: "Beat Name", subheading: "Unlost" },
  ]);

  const [button, setButton] = useState("users");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };

  return (
    <div className="search container container-xxl min-height-cover">
      <h1 className="search-heading mx-0 my-0">Favorites</h1>
      {button === "search" ? (
        <DropworksSearchInput className="search-inputbox uni-my-40" />
      ) : (
        ""
      )}

      <div className={`search-button d-flex flex-wrap mb-4 ${
            button === "search" ? "" : "mt-3"
          }`}>
        <button
          className={`search-button-all uni-mx-8 ${
            button === "users" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("users")}
        >
          Users
        </button>
        <button
          className={`search-button-all ${button === "tracks" ? "active" : ""}`}
          onClick={() => handleButtonClick("tracks")}
        >
          Tracks
        </button>
        <button
          className={`search-button-all uni-mx-8 ${
            button === "projects" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("projects")}
        >
          Projects
        </button>
        <button
          className={`search-button-all ${button === "beats" ? "active" : ""}`}
          onClick={() => handleButtonClick("beats")}
        >
          Beats
        </button>
        <button
          className={`search-button-all ${button === "search" ? "active" : ""}`}
          onClick={() => handleButtonClick("search")}
        >
          <img src={searchIcon} className="custom-search-icon " alt="icon" />
        </button>
      </div>

      {button === "all" || button === "users" ? (
        <>
          {button === "all" ? (
            ""
          ) : (
            <div className="mb-4">
              <DropworksDropdown arr={dropdownarrRoles} />
            </div>
          )}
          <div className="search-usericon">
            <UserIcon arr={beatsarr} />
          </div>
        </>
      ) : null}
      {button === "search" ? (
        <>
          <div className="search-usericon">
            <BeatesIcon arr={beatsarr} />
          </div>
        </>
      ) : null}

      {button === "tracks" ? (
        <>
          {button === "tracks" ? (
            ""
          ) : (
            <div className="search-subheading">Tracks</div>
          )}

          <div className="mb-4">
            <DropworksDropdown arr={dropdownarrTracks} />
          </div>

          <div className="search-usericon">
            <TrackIcon arr={trackarr} />
          </div>
        </>
      ) : null}

      {button === "projects" ? (
        <>
          {button === "projects" ? (
            ""
          ) : (
            <div className="search-subheading">Projects</div>
          )}

          <div className="search-usericon">
            <ProjectIcon arr={projectarr} />
          </div>
        </>
      ) : null}

      {button === "beats" ? (
        <>
          {button === "beats" ? (
            ""
          ) : (
            <div className="search-subheading">Beats</div>
          )}
          <div className="search-usericon">
            <BeatesIcon arr={beatsarr} />
          </div>
        </>
      ) : null}
    </div>
  );
};
