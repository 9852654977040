import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { DropworksDropdownCountry } from '../../../../../common/custom-field/dropworks-dropdown-country';
import { ButtonComponent } from '../../logo-header/view';

const view = () => {
  return (
    <div
    className="modal fade"
    id="SecureYourAccount"
    aria-hidden="true"
    aria-labelledby="SecureYourAccount"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content ">
        <div className="modal-header p-0">
          <h1 className="modal-title">Secure your account</h1>
        </div>
        
        <div className="modal-body p-0">
          <TextfieldLG className="uni-mt-16 uni-mb-8 ctm-txt">Add a valid phone number</TextfieldLG>
          <TextfieldXSM className="ctm-txt2 uni-mb-56">(Optional)</TextfieldXSM>
         <p className=' d-flex'>
          <DropworksDropdownCountry className="uni-mr-16 uni-mb-8"/> <DropworksInput className="mb-0" placeholder="Phone number" />
          </p> 
          
        </div>  
        <div className="uni-mt-40 modal-footer p-0">
        <ButtonComponent id="Modal4">
          <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton>
          </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;


